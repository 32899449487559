import React from 'react'
import "./style/ServiceCard.css"

const ServiceCard = ({scrollRef, imagePath, imageAlt, modifier, text}) => {
  return (
    <div ref={scrollRef} className={`service__card service__card--${modifier}`}>
        <img width={"200px"} height={"150px"} className='service__card-img' src={imagePath} alt={imageAlt} />

        <p className="service__card-description">
            {text}
        </p>
    </div>
  )
}

export default ServiceCard