import React, { useEffect, useRef } from 'react';
import "./style/Statistic.css"

const Statistic = ({languageTexts, selectedLanguage}) => {
  const elementRefs = {
    title: useRef(null),
    card1: useRef(null),
    card2: useRef(null),
    card3: useRef(null),
    card4: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight) {
            element.classList.add('animate');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

  return (
    <div className='statistic'>

      <h2 className="statistic__title secondary-title" ref={elementRefs.title}>
        {languageTexts[selectedLanguage].statistic.title}
      </h2>

      <div className="statistic__wrapper">
        <div className="statistic__card" ref={elementRefs.card1}>
          <div className="statistic__card-number">
            {languageTexts[selectedLanguage].statistic.number1}
          </div>
          <p className="statistic__card-description">
            {languageTexts[selectedLanguage].statistic.text1}
          </p>
        </div>

        <div className="statistic__card" ref={elementRefs.card2}>
          <div className="statistic__card-number">
            {languageTexts[selectedLanguage].statistic.number2}
          </div>
          <p className="statistic__card-description">
            {languageTexts[selectedLanguage].statistic.text2}
          </p>
        </div>

        <div className="statistic__card" ref={elementRefs.card3}>
          <div className="statistic__card-number">
            {languageTexts[selectedLanguage].statistic.number3}
          </div>
          <p className="statistic__card-description">
            {languageTexts[selectedLanguage].statistic.text3}
          </p>
        </div>

        <div className="statistic__card" ref={elementRefs.card4}>
          <div className="statistic__card-number">
            {languageTexts[selectedLanguage].statistic.number4}
          </div>
          <p className="statistic__card-description">
            {languageTexts[selectedLanguage].statistic.text4}
          </p>
        </div>
      </div>

    </div>
  )
}

export default Statistic;
