import React from 'react'
import "./style/SocialMedie.css"

const SocialMedie = ({linkPath, icon, theme, type}) => {
  return (
    <a href={linkPath} target='_blank' aria-label='Social media' className={`social-meadia social-meadia--${theme} social-meadia--${type}`}>
      {icon}
    </a>
  )
}

export default SocialMedie