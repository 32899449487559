import React, { useEffect, useRef, useState } from 'react';
import './style/FAQ.css';
import QuestionCard from '../UI/QuestionCard/QuestionCard';

const FAQ = ({ languageTexts, selectedLanguage }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCards([
      {
        question: `${languageTexts[selectedLanguage].faq.question2}`,
        answer: `
          <ul>        
            <li>
              <p> <strong>1.</strong> ${languageTexts[selectedLanguage].faq.answer2SubTitle1}</p>
              <br/>
              ${languageTexts[selectedLanguage].faq.answer2SubDescription1}
            </li>

            <li>
            <p>2. ${languageTexts[selectedLanguage].faq.answer2SubTitle2}</p>
            <br/>
            ${languageTexts[selectedLanguage].faq.answer2SubDescription2}
            </li>

            <li>
            <p>3. ${languageTexts[selectedLanguage].faq.answer2SubTitle3}</p>
            <br/>
            ${languageTexts[selectedLanguage].faq.answer2SubDescription3}
            </li>

            <li>
            <p>4. ${languageTexts[selectedLanguage].faq.answer2SubTitle4}</p>
            <br/>
            ${languageTexts[selectedLanguage].faq.answer2SubDescription4}
            </li>

            <li>
            <p>5. ${languageTexts[selectedLanguage].faq.answer2SubTitle5}</p>
            <br/>
            ${languageTexts[selectedLanguage].faq.answer2SubDescription5}
            </li>
          </ul>`,
        isOpen: false, animationClass: "animation__left"
      },
      { question: languageTexts[selectedLanguage].faq.question4, answer: languageTexts[selectedLanguage].faq.answer4, isOpen: false, animationClass: "animation__right" },
      { question: languageTexts[selectedLanguage].faq.question1, answer: `
      <ul>        
        <li>
          <p>${languageTexts[selectedLanguage].faq.answer1SubTitle1}</p>
          <br/>
          <strong>1.</strong> ${languageTexts[selectedLanguage].faq.answer1SubDescription1}
          <br/>
          <br/>
          <strong>2.</strong> ${languageTexts[selectedLanguage].faq.answer1SubDescription2}
          <br/>
          <br/>
          <strong>3.</strong> ${languageTexts[selectedLanguage].faq.answer1SubDescription3}
        </li>

        <br/>

        <li>
        <p>${languageTexts[selectedLanguage].faq.answer1SubTitle2}</p>
        <br/>
        <strong>1.</strong> ${languageTexts[selectedLanguage].faq.answer1SubDescription4}
        <br/>
        <br/>
        <strong>2.</strong> ${languageTexts[selectedLanguage].faq.answer1SubDescription5}
        <br/>
        <br/>
        <strong>3.</strong> ${languageTexts[selectedLanguage].faq.answer1SubDescription6}
       </li>

      </ul>`, isOpen: false, animationClass: "animation__left" },

      { question: languageTexts[selectedLanguage].faq.question5, answer: languageTexts[selectedLanguage].faq.answer5, isOpen: false, animationClass: "animation__right" },
      { question: languageTexts[selectedLanguage].faq.question3, answer: languageTexts[selectedLanguage].faq.answer3, isOpen: false, animationClass: "animation__right" },

    ]);
  }, [selectedLanguage]);

  const toggleCard = (clickedQuestion) => {
    const updatedCards = cards.map((card) => {
      if (card.question === clickedQuestion) {
        return {
          ...card,
          isOpen: !card.isOpen,
          animationClass: card.isOpen ? '' : card.animationClass,
        };
      }
      return { ...card, isOpen: false };
    });
  
    setCards(updatedCards);
  };


  const elementRefs = {
    title: useRef(null),
  };
  
  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight ) {
            element.classList.add('animate');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

  return (
    <section className="faq" id='faq'>
      <h2 className="faq__title secondary-title" ref={elementRefs.title}>
        FAQ
      </h2>
      
      <div className="faq__content">
        {cards.map(card => (
          <QuestionCard
            key={card.question}
            question={card.question}
            answer={card.answer}
            isOpen={card.isOpen}
            toggleCard={toggleCard}
            animationClass={card.animationClass}
          />
        ))}
      </div>
    </section>
  );
}

export default FAQ;
