import React, { useEffect, useRef } from 'react'
import "./style/WorkProcess.css"

const WorkProcess = ({languageTexts, selectedLanguage}) => {
  const elementRefs = {
    title: useRef(null),
    text1: useRef(null),
    text2: useRef(null),
    text3: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight ) {
            element.classList.add('animate');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

  
  return (
    <div className='work' id='work'>
      <div className="work__title secondary-title" ref={elementRefs.title}>
        {languageTexts[selectedLanguage].work.title} <br />  {languageTexts[selectedLanguage].work.subtitle}
      </div>

      <div className="work__box">

        <div className="work__box-number">
            <div className="work__box-element">
              <div className="work__box-round">01</div>
            </div>

            <div className="work__box-element">
              <div className="work__box-round">02</div>
            </div>

            <div className="work__box-element">
              <div className="work__box-round">03</div>
            </div>
            
        </div>

        <div className="work__box-container work__box-container1">
          <div className="work__text" ref={elementRefs.text1}>
            <p className='work__text-title'>{languageTexts[selectedLanguage].work.subTitle1}</p>
            <p className='work__text-description'>{languageTexts[selectedLanguage].work.description1}</p>
          </div>
        </div>

        <div className="work__box-container work__box-container2">
          <div className="work__text" ref={elementRefs.text2}>
            <p className='work__text-title'>{languageTexts[selectedLanguage].work.subTitle2}</p>
            <p className='work__text-description'>{languageTexts[selectedLanguage].work.description2}</p>
          </div>
        </div>

        <div className="work__box-container work__box-container1">
          <div className="work__text" ref={elementRefs.text3}>
            <p className='work__text-title'>{languageTexts[selectedLanguage].work.subTitle3}</p>
            <p className='work__text-description'>{languageTexts[selectedLanguage].work.description3}</p>
          </div>
        </div>

      </div>
    </div>
  )
}

export default WorkProcess;