import React, { useEffect, useRef } from 'react'
import "./style/Service.css"
import ServiceCard from '../UI/ServiceCard/ServiceCard'

import manImage from "./img/man-image.webp"
import poppyImage from "./img/poppy-image.png"
import palletImage from "./img/pallet-image.webp"
import sugarImage from "./img/sugar.jpg"
import snailImage from "./img/snail-image.png"
import logisticImage from "./img/logistic.png"


const Service = ({languageTexts, selectedLanguage}) => {
  const elementRefs = {
    title: useRef(null),
    card1: useRef(null),
    card2: useRef(null),
    card3: useRef(null),
    card4: useRef(null),
    card5: useRef(null),
    card6: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight ) {
            element.classList.add('animate');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

   return (
    <div id='service' className="service__wrapper">
      <section className='service'>
        <h2 className="service__title secondary-title" ref={elementRefs.title}>
        {languageTexts[selectedLanguage].service.title}
        </h2>

        <div className="service__row">
          <ServiceCard
            scrollRef={elementRefs.card2}
            imagePath = {poppyImage}
            imageAlt = "poppy-image"
            modifier = "poppy"
            text = {languageTexts[selectedLanguage].service.description1}
          />
          <ServiceCard
            scrollRef={elementRefs.card4}
            imagePath = {sugarImage}
            imageAlt = "sugar"
            modifier = "sugar"
            text = {languageTexts[selectedLanguage].service.description2}
          />
          <ServiceCard
            scrollRef={elementRefs.card5}
            imagePath = {snailImage}
            imageAlt = "snail-image"
            modifier = "snail"
            text = {languageTexts[selectedLanguage].service.description3}
          />
          <ServiceCard
            scrollRef={elementRefs.card1}
            imagePath = {manImage}
            imageAlt = "man-image"
            modifier = "man"
            text = {languageTexts[selectedLanguage].service.description4}
          />

          <ServiceCard
            scrollRef={elementRefs.card6}
            imagePath = {logisticImage}
            imageAlt = "logistic-image"
            modifier = "logistic"
            text = {languageTexts[selectedLanguage].service.description5}
          />

          <ServiceCard
            scrollRef={elementRefs.card3}
            imagePath = {palletImage}
            imageAlt = "pallet-image"
            modifier = "pallet"
            text = {languageTexts[selectedLanguage].service.description6}
            />
        </div>
      </section>
    </div>

  )
}

export default Service