import React, { useEffect, useRef } from 'react'
import mapImage from "./img/about-us__map.svg"
import "./style/AboutUs.css"

const AboutUs = ({languageTexts, setSelectedLanguage, selectedLanguage}) => {

  const elementRefs = {
    left: useRef(null),
    right: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight) {
            element.classList.add('animate');
          }
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);


  return (
    <section id='about-us' className='about-us'>
      <div className="about-us__row">
        <div className="about-us__content" ref={elementRefs.left}>
          <h2 className='about-us__title secondary-title'>{languageTexts[selectedLanguage].about.title}</h2>

          <p className='about-us__text'>
            {languageTexts[selectedLanguage].about.desription1}
          </p>

          <p className='about-us__text'>
            {languageTexts[selectedLanguage].about.desription2}
          </p>


          <div className="about-us__experience">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.6833 9.14992V10.3833L23.7833 6.96658C21.55 5.68325 18.4333 5.68325 16.2166 6.96658L10.3167 10.3999V9.14992C10.3167 5.39992 12.3667 3.33325 16.1167 3.33325H23.8833C27.6333 3.33325 29.6833 5.39992 29.6833 9.14992Z" fill="#006CC0"/>
              <path d="M29.7333 13.2832L29.5 13.1666L27.2333 11.8666L22.5333 9.1499C21.1 8.31657 18.9 8.31657 17.4666 9.1499L12.7666 11.8499L10.5 13.1832L10.2 13.3332C7.28331 15.2999 7.08331 15.6666 7.08331 18.8166V26.3499C7.08331 29.4999 7.28331 29.8666 10.2666 31.8832L17.4666 36.0332C18.1833 36.4666 19.0833 36.6499 20 36.6499C20.9 36.6499 21.8166 36.4499 22.5333 36.0332L29.8 31.8332C32.7333 29.8666 32.9166 29.5166 32.9166 26.3499V18.8166C32.9166 15.6666 32.7166 15.2999 29.7333 13.2832ZM24.65 22.4999L23.6333 23.7499C23.4666 23.9332 23.35 24.2832 23.3666 24.5332L23.4666 26.1332C23.5333 27.1166 22.8333 27.6166 21.9166 27.2666L20.4333 26.6666C20.2 26.5832 19.8166 26.5832 19.5833 26.6666L18.1 27.2499C17.1833 27.6166 16.4833 27.0999 16.55 26.1166L16.65 24.5166C16.6666 24.2666 16.55 23.9166 16.3833 23.7332L15.35 22.4999C14.7166 21.7499 15 20.9166 15.95 20.6666L17.5 20.2666C17.75 20.1999 18.0333 19.9666 18.1666 19.7666L19.0333 18.4332C19.5666 17.5999 20.4166 17.5999 20.9666 18.4332L21.8333 19.7666C21.9666 19.9832 22.2666 20.1999 22.5 20.2666L24.05 20.6666C25 20.9166 25.2833 21.7499 24.65 22.4999Z" fill="#006CC0"/>
            </svg>
            <span className='about-us__experience-text'>{languageTexts[selectedLanguage].about.subTitle}</span>
          </div>
        </div>


        <img width={"500px"} height={"600px"} src={mapImage} alt="Map" className="about-us__image" ref={elementRefs.right} />
      </div>
    </section>
  )
}

export default AboutUs