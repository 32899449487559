import React, { useEffect, useRef, useState } from 'react';
import SocialMedie from '../UI/SocialMedia/SocialMedie'
import "./style/Home.css";
import logo from "../main/img/logo.svg";

const Home = ({languageTexts, setSelectedLanguage, selectedLanguage}) => {
  const [languageBoxesVisible, setLanguageBoxesVisible] = useState(false);
  const [burgerOpen, setBurgerOpen] = useState(false);
  const myRef = useRef(null);

  let burgerRow1 = burgerOpen ? "box__burger-row box__burger-row1 box__burger-row1Show" : "box__burger-row box__burger-row1";
  let burgerRow2 = burgerOpen ? "box__burger-row box__burger-row2 box__burger-row2Show" : "box__burger-row box__burger-row2";
  let burgerRow3 = burgerOpen ? "box__burger-row box__burger-row3 box__burger-row3Show" : "box__burger-row box__burger-row3";
  let burgerPopup = burgerOpen ? "home__popup home__popup-open" : "home__popup";

  let burgerNavigation = burgerOpen ? "box__navigation box__navigation-open" : "box__navigation";

  function changePageTitle() {
    document.title = `OKFG s.r.o. - ${languageTexts[selectedLanguage].home.description}`;
  }
  changePageTitle();


  const handleChangeLanguage = (language) => {
    changePageTitle();
    const myElement = document.getElementById('popopLanguage');

    if (myElement) {
      myElement.classList.add("home__language-open");
    }

    setTimeout(() => {
      myElement.classList.remove("home__language-open");
      document.body.classList.remove("body-hidden"); 
    }, 2000);

    setSelectedLanguage(language);
    console.log(language);
  
    const url = new URL(window.location.href);
    url.pathname = `/${language}${url.pathname.replace(/\/(ua|cz|eu)/, '')}`;
    url.pathname = url.pathname.replace(/\/$/, '');
    window.history.replaceState({}, '', url.toString());
    localStorage.setItem('selectedLanguage', language);
    
  };
  
  useEffect(() => {
    const storedLanguage = localStorage.getItem('selectedLanguage');

    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    } else {
      const path = window.location.pathname.toLowerCase();
      if (path === '/' || path.startsWith('/cz')) {
        setSelectedLanguage('cz');
      } else if (path.startsWith('/ua')) {
        setSelectedLanguage('ua');
      } else if (path.startsWith('/eu')) {
        setSelectedLanguage('eu');
      } else {
        setSelectedLanguage('eu');
        window.history.replaceState({}, '', "/eu");
      }
    }
  }, [setSelectedLanguage]);
  
  useEffect(() => {
    const popupTimer = setTimeout(() => {
      document.body.classList.remove("body-hidden"); 
    }, 3500);

    return () => {
      clearTimeout(popupTimer);
    };
  }, []);



  const hadleOpenLanguage = () => {
    setLanguageBoxesVisible(!languageBoxesVisible); 
  }

  const hadleOpenBurger = () => {
    setBurgerOpen(!burgerOpen);
    
    document.body.classList.toggle("body-hidden", !burgerOpen); 
    // myRef.current.scrollIntoView();

  }

  // nav scroll
  const handleScrollToSection = (sectionId) => {
    setBurgerOpen(false);
    
    document.body.classList.remove("body-hidden", false); 

    if (!sectionId || sectionId === 'top') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
  
    const sectionRef = document.getElementById(sectionId);
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
  
    const distanceToBottom = documentHeight - (scrollPosition + windowHeight);
  
    if (scrollPosition > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  
    if (distanceToBottom < 40) {
      setIsScrolled(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Плавная прокрутка
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




  // Fixed Header
  const [scrollingDown, setScrollingDown] = useState(false);
  const [isUserOnTop, setIsUserOnTop] = useState(true);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setScrollingDown(prevScrollPos < currentScrollPos);
      prevScrollPos = currentScrollPos;

      currentScrollPos > 0 ? setIsUserOnTop(false) : setIsUserOnTop(true)
    };



    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  

  

  return (
    <section ref={myRef} className='home'>
      <div onClick={scrollToTop} className={`home__scroll-top ${isScrolled ? "_open" : ""}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <path d="M20.0001 10V30M20.0001 10L11.6667 18.3333M20.0001 10L28.3334 18.3333" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>

      <div className="home__language" id='popopLanguage'></div>

      <div className="page__popup">
        <div className="logo"></div>
      </div>

      <div className={burgerPopup}></div>

      <div className="home__header--phantom"></div>

      <header
        className={`home__header ${!scrollingDown ? "_header-show" : "_header-hide"} ${burgerOpen ? "_hide-bg" : ""}`}
        style={{
          backgroundColor: isUserOnTop ? "transparent" : "rgba(0, 0, 0, 0.4)",
          backdropFilter: isUserOnTop ? "blur(0px)" : "blur(5px)",

          backgroundColor: burgerOpen ? "transparent" : "rgba(0, 0, 0, 0.4)",
          backdropFilter: burgerOpen ? "blur(0px)" : "blur(5px)",
        }}
      >
        <div className="logo">
          <img width={"100px"} height={"50px"} src={logo} alt="Logo" />
        </div>

        <div className="box">
          <nav className={burgerNavigation}>
             <ul>
              <li onClick={() => handleScrollToSection('about-us')}>{languageTexts[selectedLanguage].home.nav1}</li>
              <li onClick={() => handleScrollToSection('service')}>{languageTexts[selectedLanguage].home.nav2}</li>
              <li onClick={() => handleScrollToSection('work')}>{languageTexts[selectedLanguage].home.nav3}</li>
              <li onClick={() => handleScrollToSection('faq')}>{languageTexts[selectedLanguage].home.nav4}</li>
              <li onClick={() => handleScrollToSection('contact')}>{languageTexts[selectedLanguage].home.nav5}</li>
            </ul>
          </nav>

          <div className="box__language" onClick={hadleOpenLanguage}>
            <div className="box__language-box">
              <div className={`box__language-flat box__language-main box__language-${selectedLanguage}`}>
              </div>
              <div className={`box__language-arrow  ${languageBoxesVisible ? "box__language-arrowimgRow" : "box__language-arrowimg"}`}></div>
            </div>

            {selectedLanguage !== 'cz' && (
              <div className={`box__language-box ${languageBoxesVisible ? "box__language-boxShow" : "box__language-boxHide"}`} onClick={() => handleChangeLanguage('cz')}>
                <div className="box__language-flat box__language-main box__language-cz"></div>
                <div className="box__language-arrow box__language-arrow"></div>
              </div>
            )}

            {selectedLanguage !== 'eu' && (
              <div className={`box__language-box ${languageBoxesVisible ? "box__language-boxShow" : "box__language-boxHide"}`} onClick={() => handleChangeLanguage('eu')}>
                <div className="box__language-flat box__language-eu"></div>
                <div className="box__language-arrow"></div>
              </div>
            )}

            {selectedLanguage !== 'ua' && (
              <div className={`box__language-box ${languageBoxesVisible ? "box__language-boxShow" : "box__language-boxHide"}`} onClick={() => handleChangeLanguage('ua')}>
                <div className="box__language-flat box__language-ua"></div>
                <div className="box__language-arrow"></div>
              </div>
            )}
          </div>

          <div className="box__burger" onClick={hadleOpenBurger}>
              <span className={burgerRow1}></span>
              <span className={burgerRow2}></span>
              <span className={burgerRow3}></span>

          </div>

        </div>
      </header>
      
      <main className="main">
        <div className="main__element">
          <h1 className='main__element-title'>{languageTexts[selectedLanguage].home.title1} <br /> 
          <span className='main__element-color'>{languageTexts[selectedLanguage].home.title2}</span> <br /> 
          {languageTexts[selectedLanguage].home.title3}</h1>
        </div>

        <div className="main__container">
          <p className='main__container-descripiton'>{languageTexts[selectedLanguage].home.description}</p>
          <div className="main__container-sociable">
          <SocialMedie
              linkPath="https://t.me/andriiokfg"
              theme='white'
              icon = {
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                  <path d="M12.7974 22.1852C12.7974 22.1852 22.156 18.2435 25.4017 16.8556C26.6459 16.3005 30.8653 14.5239 30.8653 14.5239C30.8653 14.5239 32.8128 13.7467 32.6505 15.6342C32.5964 16.4115 32.1636 19.1318 31.7309 22.0742C31.0817 26.238 30.3785 30.7903 30.3785 30.7903C30.3785 30.7903 30.2703 32.0673 29.3507 32.2893C28.4311 32.5114 26.9163 31.5121 26.6459 31.29C26.4295 31.1235 22.5887 28.6252 21.1822 27.4038C20.8035 27.0707 20.3708 26.4046 21.2363 25.6273C23.1838 23.7952 25.5099 21.519 26.9163 20.0756C27.5655 19.4094 28.2146 17.8549 25.5099 19.7425C21.6691 22.4628 17.8824 25.0166 17.8824 25.0166C17.8824 25.0166 17.0168 25.5717 15.394 25.0721C13.7711 24.5725 11.8777 23.9063 11.8777 23.9063C11.8777 23.9063 10.5795 23.0735 12.7974 22.1852Z" fill="#006CC0"/>
                </svg>
              }
            />
            <SocialMedie
            linkPath="https://wa.me/420732355845"
              theme='white'
              icon = {
                <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                  <path d="M11.5001 33.6484L13.1503 27.5125C11.8195 25.1223 11.3967 22.3332 11.9594 19.6568C12.5222 16.9805 14.0329 14.5965 16.2142 12.9424C18.3955 11.2883 21.1011 10.475 23.8346 10.6517C26.5681 10.8284 29.146 11.9833 31.0954 13.9044C33.0449 15.8256 34.2349 18.3841 34.4473 21.1106C34.6596 23.837 33.88 26.5485 32.2515 28.7474C30.623 30.9463 28.2549 32.4851 25.5816 33.0816C22.9084 33.678 20.1093 33.292 17.6981 31.9944L11.5001 33.6484ZM17.997 29.6984L18.3803 29.9254C20.1268 30.9588 22.1666 31.3865 24.182 31.1417C26.1974 30.8969 28.075 29.9935 29.5223 28.5721C30.9697 27.1507 31.9054 25.2913 32.1837 23.2835C32.462 21.2758 32.0672 19.2326 31.0608 17.4722C30.0545 15.7119 28.4931 14.3333 26.6201 13.5514C24.747 12.7694 22.6675 12.628 20.7055 13.1492C18.7436 13.6703 17.0094 14.8248 15.7733 16.4327C14.5371 18.0405 13.8685 20.0114 13.8715 22.0383C13.8698 23.7189 14.3354 25.367 15.2163 26.7991L15.4567 27.1947L14.5341 30.6194L17.997 29.6984Z" fill="#006CC0"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M27.3002 23.6139C27.0755 23.433 26.8124 23.3056 26.5309 23.2416C26.2495 23.1775 25.9571 23.1784 25.676 23.2442C25.2537 23.4193 24.9808 24.0809 24.708 24.4117C24.6504 24.491 24.5659 24.5466 24.4702 24.5681C24.3745 24.5896 24.2742 24.5755 24.1882 24.5285C22.6422 23.9239 21.3463 22.8153 20.511 21.3827C20.4398 21.2933 20.406 21.1798 20.4169 21.0661C20.4279 20.9524 20.4825 20.8473 20.5695 20.773C20.8738 20.4722 21.0973 20.0997 21.2192 19.6898C21.2462 19.2378 21.1425 18.7875 20.9203 18.3926C20.7485 17.8391 20.4217 17.3462 19.9783 16.9722C19.7496 16.8695 19.496 16.8351 19.2482 16.873C19.0003 16.911 18.7688 17.0198 18.5814 17.1862C18.2563 17.4663 17.9981 17.8157 17.8261 18.2086C17.6541 18.6014 17.5725 19.0278 17.5874 19.4563C17.5884 19.697 17.619 19.9366 17.6784 20.1698C17.8292 20.7302 18.0612 21.2655 18.367 21.7589C18.5877 22.1369 18.8284 22.5028 19.0882 22.8551C19.9325 24.0122 20.9938 24.9946 22.2132 25.7478C22.8251 26.1306 23.4791 26.4418 24.1622 26.6754C24.8719 26.9965 25.6554 27.1198 26.4296 27.0321C26.8707 26.9654 27.2887 26.7916 27.6467 26.5258C28.0047 26.26 28.2917 25.9104 28.4826 25.5079C28.5948 25.2647 28.6288 24.9928 28.5801 24.7295C28.4631 24.1912 27.742 23.8734 27.3002 23.6139Z" fill="#006CC0"/>
                </svg>
              }
            />
            <SocialMedie
              linkPath="https://msng.link/o?420732355845=vi"
              theme='white'
              icon = {
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="46"
                  height="46"
                  fill="none"
                  viewBox="0 0 46 46"
                >
                  <path
                    fill="#006CC0"
                    d="M17.632 32.403v-1.156a.117.117 0 00-.104-.136 6.682 6.682 0 01-2.605-1.415 6.262 6.262 0 01-1.343-1.724 9.363 9.363 0 01-.951-2.987 21.874 21.874 0 01-.189-3.943 19.42 19.42 0 01.115-1.836c.112-1.167.41-2.31.88-3.385a5.864 5.864 0 012.962-2.982c.903-.409 1.853-.708 2.828-.89.957-.179 1.926-.29 2.899-.33.877-.03 1.755-.017 2.63.04 1.254.052 2.496.257 3.698.612.892.254 1.74.64 2.517 1.143a5.373 5.373 0 011.778 2.025c.482.95.81 1.97.97 3.022.105.624.174 1.254.207 1.886.045.784.04 1.57.01 2.35s-.097 1.538-.194 2.302a8.476 8.476 0 01-1.046 3.264 6.061 6.061 0 01-3.328 2.704c-.96.334-1.953.571-2.962.709-.737.101-1.477.174-2.22.204-.569.026-1.138.02-1.706.01-.323 0-.643-.03-.964-.059a.164.164 0 00-.165.07 78.866 78.866 0 01-1.999 2.268 1.888 1.888 0 01-.424.35.796.796 0 01-1.194-.44c-.067-.21-.1-.43-.099-.65l-.001-1.026zm.783 1.464l.083-.076 1.47-1.618c.566-.622 1.131-1.245 1.696-1.869a.268.268 0 01.247-.108c.192.02.383.026.577.028.67 0 1.338 0 2.008-.047.607-.047 1.213-.12 1.815-.215.649-.111 1.29-.258 1.923-.437 1.409-.375 2.499-1.178 3.184-2.478.404-.8.658-1.665.748-2.554.107-.839.171-1.682.192-2.527a16.208 16.208 0 00-.218-3.17 8.912 8.912 0 00-.655-2.202c-.398-.872-.962-1.595-1.83-2.059-1.331-.712-2.771-1.029-4.256-1.191-.42-.046-.842-.07-1.264-.086a23.036 23.036 0 00-2.598.014c-.622.046-1.24.129-1.852.248-.9.162-1.77.457-2.583.872-.472.236-.9.55-1.265.927a5.81 5.81 0 00-1.271 2.284c-.29.986-.448 2.005-.47 3.032a22.779 22.779 0 00.038 3.081c.052.61.155 1.215.309 1.808a6.076 6.076 0 001.296 2.505 5.334 5.334 0 002.509 1.543c.121.036.176.078.176.214-.01.503 0 1.005 0 1.508l-.009 2.573z"
                  ></path>
                  <path
                    fill="#006CC0"
                    d="M17.183 18.283c-.012-.382.197-.66.45-.909a6.35 6.35 0 011.1-.835.887.887 0 011.139.184c.479.5.908 1.043 1.28 1.625.184.261.324.552.412.858a.69.69 0 01-.176.688c-.203.197-.42.38-.648.55a.744.744 0 00-.255.78c.14.659.46 1.268.924 1.761a5.35 5.35 0 002.113 1.548c.203.103.434.134.657.089a.603.603 0 00.31-.2c.123-.156.257-.306.368-.472a.98.98 0 011.331-.26c.597.343 1.16.74 1.684 1.187.123.104.252.206.375.312a.83.83 0 01.27.97 2.563 2.563 0 01-.47.775c-.249.344-.567.634-.934.851a1.311 1.311 0 01-1.17.075 15.483 15.483 0 01-3.95-2.301 15.383 15.383 0 01-3.028-3.315 13.726 13.726 0 01-1.673-3.334 4.211 4.211 0 01-.106-.435.919.919 0 01-.003-.192zM23.262 16.427a5.118 5.118 0 013.24 1.23 5.033 5.033 0 011.691 3.003c.077.424.118.853.123 1.284 0 .1-.026.174-.135.185-.11.01-.138-.082-.138-.175a5.796 5.796 0 00-.528-2.528 4.68 4.68 0 00-1.327-1.674 4.736 4.736 0 00-1.94-.917 5.238 5.238 0 00-1.142-.133.396.396 0 01-.111-.014.123.123 0 01-.086-.146.109.109 0 01.07-.101.111.111 0 01.044-.007c.08-.01.16-.007.24-.007z"
                  ></path>
                  <path
                    fill="#006CC0"
                    d="M26.662 21.159a3.08 3.08 0 00-.352-1.392 3.112 3.112 0 00-.946-1.086 3.346 3.346 0 00-1.564-.58c-.095-.013-.19-.018-.287-.029-.097-.01-.155-.05-.146-.148.009-.098.07-.133.176-.126 1.56.094 2.888.969 3.297 2.596.074.314.112.636.11.958v.087c0 .088-.056.145-.142.144a.14.14 0 01-.136-.152c-.014-.082-.01-.166-.01-.272z"
                  ></path>
                  <path
                    fill="#006CC0"
                    d="M23.928 19.215c.856.057 1.453.473 1.617 1.375.02.115.034.232.042.349 0 .094-.021.174-.125.174s-.139-.07-.143-.174c0-.346-.108-.683-.308-.967a1.306 1.306 0 00-.841-.468 1.912 1.912 0 00-.224-.027c-.123 0-.192-.061-.176-.156.016-.094.09-.111.158-.106z"
                  ></path>
                </svg>
              }
            />
          </div>
        </div>
      </main>

    </section>
  );
}

export default Home;
