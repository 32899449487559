import React, { useEffect, useRef, useState } from 'react'
import "./style/Footer.css"
import SocialMedie from '../UI/SocialMedia/SocialMedie'
import Logo from "./../main/img/logo.svg"

const Footer = ({languageTexts, selectedLanguage}) => {
  const contactInfo = languageTexts[selectedLanguage].contact;
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const interval = setInterval(() => {
      const newYear = new Date().getFullYear();
      if (newYear !== currentYear) {
        setCurrentYear(newYear);
      }
    }, 86400000);

    return () => clearInterval(interval);
  }, [currentYear]);



  const handleScrollToSection = (sectionId) => {
    if (!sectionId || sectionId === 'top') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      return;
    }
  
    const sectionRef = document.getElementById(sectionId);
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const elementRefs = {
    card1: useRef(null),
    card2: useRef(null),
    card3: useRef(null),
    card4: useRef(null),
    card5: useRef(null),
    column1: useRef(null),
    column2: useRef(null),
    column3: useRef(null),
    column4: useRef(null),
    description: useRef(null),
    copyright: useRef(null),
  };

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight * 1) {
            element.classList.add('animate');
          }
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);


  return (
    <div className="footer__wrapper">
      <div className="footer__card" >
          <div className="footer__card-card" ref={elementRefs.card1}>
            <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94" fill="none">
              <path d="M62.6667 23.4999C62.6667 32.1524 55.6525 39.1666 47 39.1666C38.3475 39.1666 31.3333 32.1524 31.3333 23.4999C31.3333 14.8475 38.3475 7.83325 47 7.83325C55.6525 7.83325 62.6667 14.8475 62.6667 23.4999Z" fill="#006CC0"/>
              <path d="M61.4063 52.886C59.5534 52.9084 57.8264 52.976 56.3214 53.1784C53.8034 53.5169 51.0473 54.326 48.77 56.6033C46.4928 58.8806 45.6836 61.6367 45.3451 64.1546C45.0409 66.4174 45.0413 69.182 45.0417 72.1217V72.7951C45.0413 75.7347 45.0409 78.4993 45.3451 80.7621C45.5826 82.5289 46.0519 84.4131 47.0976 86.1667C47.0651 86.1667 47.0326 86.1667 47 86.1667C15.6667 86.1667 15.6667 78.2758 15.6667 68.5417C15.6667 58.8077 29.6951 50.9167 47 50.9167C52.1942 50.9167 57.0932 51.6277 61.4063 52.886Z" fill="#006CC0"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M64.625 86.1667C58.1628 86.1667 54.9317 86.1667 52.9242 84.1591C50.9167 82.1516 50.9167 78.9205 50.9167 72.4583C50.9167 65.9962 50.9167 62.7651 52.9242 60.7575C54.9317 58.75 58.1628 58.75 64.625 58.75C71.0872 58.75 74.3182 58.75 76.3258 60.7575C78.3333 62.7651 78.3333 65.9962 78.3333 72.4583C78.3333 78.9205 78.3333 82.1516 76.3258 84.1591C74.3182 86.1667 71.0872 86.1667 64.625 86.1667ZM72.3331 69.5044C73.2254 68.6122 73.2254 67.1656 72.3331 66.2733C71.4409 65.3811 69.9943 65.3811 69.102 66.2733L61.5787 73.7967L60.1479 72.3659C59.2557 71.4737 57.8091 71.4737 56.9169 72.3659C56.0246 73.2582 56.0246 74.7048 56.9169 75.597L59.9631 78.6433C60.8554 79.5356 62.302 79.5356 63.1942 78.6433L72.3331 69.5044Z" fill="#006CC0"/>
            </svg>

          <p className='footer__card-name'>{contactInfo.cart1.name}</p>
          <p className='footer__card-position'>{contactInfo.cart1.position}</p>
          <div className='footer__card-languages'>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
              <g clipPath="url(#clip0_801_897)">
                <path d="M0 0.000244141H30V19.9998H0V0.000244141Z" fill="#11457E"/>
                <path d="M30 10V19.9998H0L12.6085 10H30Z" fill="#D7141A"/>
                <path d="M30 0.000244141V10H12.6085L0 0.000244141H30Z" fill="#F6F6F6"/>
              </g>
              <defs>
                <clipPath id="clip0_801_897">
                  <rect width="30" height="20" rx="2" fill="white"/>
                </clipPath>
              </defs>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
              <g clipPath="url(#clip0_801_1011)">
                <path d="M0 0H30V19.9041H0V0Z" fill="#F6F6F6"/>
                <path d="M16.875 -0.000244141H13.125V8.08566H0V11.8176H13.125V19.9035H16.875V11.8176H30V8.08566H16.875V-0.000244141Z" fill="#D80027"/>
                <path d="M23.0733 13.4133L30 17.243V13.4133H23.0733ZM18.2609 13.4133L30 19.9037V18.0684L21.5805 13.4133H18.2609ZM26.8731 19.9037L18.2609 15.1417V19.9037H26.8731Z" fill="#0052B4"/>
                <path d="M18.2609 13.4133L30 19.9037V18.0684L21.5805 13.4133H18.2609Z" fill="#F6F6F6"/>
                <path d="M18.2609 13.4133L30 19.9037V18.0684L21.5805 13.4133H18.2609Z" fill="#D80027"/>
                <path d="M5.29342 13.4131L0 16.3398V13.4131H5.29342ZM11.7391 14.2384V19.9035H1.49361L11.7391 14.2384Z" fill="#0052B4"/>
                <path d="M8.41951 13.4133L0 18.0684V19.9037L11.7391 13.4133H8.41951Z" fill="#D80027"/>
                <path d="M6.92666 6.49013L0 2.66042V6.49013H6.92666ZM11.7391 6.49013L0 -0.000244141V1.83507L8.41951 6.49013H11.7391ZM3.12691 -0.000244141L11.7391 4.76176V-0.000244141H3.12691Z" fill="#0052B4"/>
                <path d="M11.7391 6.49013L0 -0.000244141V1.83507L8.41951 6.49013H11.7391Z" fill="#F6F6F6"/>
                <path d="M11.7391 6.49013L0 -0.000244141V1.83507L8.41951 6.49013H11.7391Z" fill="#D80027"/>
                <path d="M24.7066 6.49043L30 3.56374V6.49043H24.7066ZM18.2609 5.66508V0H28.5064L18.2609 5.66508Z" fill="#0052B4"/>
                <path d="M21.5805 6.49013L30 1.83507V-0.000244141L18.2609 6.49013H21.5805Z" fill="#D80027"/>
              </g>
              <defs>
                <clipPath id="clip0_801_1011">
                  <rect width="30" height="19.9038" rx="3" fill="white"/>
                </clipPath>
              </defs>
            </svg>

              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                <g clipPath="url(#clip0_801_901)">
                  <path d="M0 0.000244141H30V19.9998H0V0.000244141Z" fill="#FFDA44"/>
                  <path d="M0 0.000244141H30V10H0V0.000244141Z" fill="#338AF3"/>
                </g>
                <defs>
                  <clipPath id="clip0_801_901">
                    <rect width="30" height="20" rx="2" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
          </div>


          <div className="footer__card-container">
            <div className="footer__card-box">
              <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#006CC0" strokeWidth="1.5" strokeMiterlimit="10"/>
              </svg>

              <a href="tel:+420 732 355 845" className='footer__card-text'>+420 732 355 845</a>
            </div>

            <div className="footer__card-box">
              <svg  className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 11.8014C2 8.0926 2 6.2382 3.17157 5.08602C4.34315 3.93384 6.22876 3.93384 10 3.93384H14C17.7712 3.93384 19.6569 3.93384 20.8284 5.08602C22 6.2382 22 8.0926 22 11.8014C22 15.5102 22 17.3646 20.8284 18.5168C19.6569 19.669 17.7712 19.669 14 19.669H10C6.22876 19.669 4.34315 19.669 3.17157 18.5168C2 17.3646 2 15.5102 2 11.8014Z" stroke="#006CC0" strokeWidth="1.5"/>
                <path d="M6 7.86768L8.1589 9.63698C9.99553 11.1422 10.9139 11.8948 12 11.8948C13.0861 11.8948 14.0045 11.1422 15.8411 9.63697L18 7.86768" stroke="#006CC0" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>

              <a href="mailto:ondrej@okfg.cz" className='footer__card-text'>ondrej@okfg.cz</a>
            </div>

            <div className="footer__sociable">
              <SocialMedie
                  linkPath="https://t.me/andriiokfg"
                  theme='blue'
                  type = "mini"
                  icon = {
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                      <path d="M12.7974 22.1852C12.7974 22.1852 22.156 18.2435 25.4017 16.8556C26.6459 16.3005 30.8653 14.5239 30.8653 14.5239C30.8653 14.5239 32.8128 13.7467 32.6505 15.6342C32.5964 16.4115 32.1636 19.1318 31.7309 22.0742C31.0817 26.238 30.3785 30.7903 30.3785 30.7903C30.3785 30.7903 30.2703 32.0673 29.3507 32.2893C28.4311 32.5114 26.9163 31.5121 26.6459 31.29C26.4295 31.1235 22.5887 28.6252 21.1822 27.4038C20.8035 27.0707 20.3708 26.4046 21.2363 25.6273C23.1838 23.7952 25.5099 21.519 26.9163 20.0756C27.5655 19.4094 28.2146 17.8549 25.5099 19.7425C21.6691 22.4628 17.8824 25.0166 17.8824 25.0166C17.8824 25.0166 17.0168 25.5717 15.394 25.0721C13.7711 24.5725 11.8777 23.9063 11.8777 23.9063C11.8777 23.9063 10.5795 23.0735 12.7974 22.1852Z" fill="#006CC0"/>
                    </svg>
                  }
                />
                <SocialMedie
                  linkPath="https://wa.me/420732355845"
                  theme='blue'
                  type = "mini"
                  icon = {
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                      <path d="M11.5001 33.6484L13.1503 27.5125C11.8195 25.1223 11.3967 22.3332 11.9594 19.6568C12.5222 16.9805 14.0329 14.5965 16.2142 12.9424C18.3955 11.2883 21.1011 10.475 23.8346 10.6517C26.5681 10.8284 29.146 11.9833 31.0954 13.9044C33.0449 15.8256 34.2349 18.3841 34.4473 21.1106C34.6596 23.837 33.88 26.5485 32.2515 28.7474C30.623 30.9463 28.2549 32.4851 25.5816 33.0816C22.9084 33.678 20.1093 33.292 17.6981 31.9944L11.5001 33.6484ZM17.997 29.6984L18.3803 29.9254C20.1268 30.9588 22.1666 31.3865 24.182 31.1417C26.1974 30.8969 28.075 29.9935 29.5223 28.5721C30.9697 27.1507 31.9054 25.2913 32.1837 23.2835C32.462 21.2758 32.0672 19.2326 31.0608 17.4722C30.0545 15.7119 28.4931 14.3333 26.6201 13.5514C24.747 12.7694 22.6675 12.628 20.7055 13.1492C18.7436 13.6703 17.0094 14.8248 15.7733 16.4327C14.5371 18.0405 13.8685 20.0114 13.8715 22.0383C13.8698 23.7189 14.3354 25.367 15.2163 26.7991L15.4567 27.1947L14.5341 30.6194L17.997 29.6984Z" fill="#006CC0"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M27.3002 23.6139C27.0755 23.433 26.8124 23.3056 26.5309 23.2416C26.2495 23.1775 25.9571 23.1784 25.676 23.2442C25.2537 23.4193 24.9808 24.0809 24.708 24.4117C24.6504 24.491 24.5659 24.5466 24.4702 24.5681C24.3745 24.5896 24.2742 24.5755 24.1882 24.5285C22.6422 23.9239 21.3463 22.8153 20.511 21.3827C20.4398 21.2933 20.406 21.1798 20.4169 21.0661C20.4279 20.9524 20.4825 20.8473 20.5695 20.773C20.8738 20.4722 21.0973 20.0997 21.2192 19.6898C21.2462 19.2378 21.1425 18.7875 20.9203 18.3926C20.7485 17.8391 20.4217 17.3462 19.9783 16.9722C19.7496 16.8695 19.496 16.8351 19.2482 16.873C19.0003 16.911 18.7688 17.0198 18.5814 17.1862C18.2563 17.4663 17.9981 17.8157 17.8261 18.2086C17.6541 18.6014 17.5725 19.0278 17.5874 19.4563C17.5884 19.697 17.619 19.9366 17.6784 20.1698C17.8292 20.7302 18.0612 21.2655 18.367 21.7589C18.5877 22.1369 18.8284 22.5028 19.0882 22.8551C19.9325 24.0122 20.9938 24.9946 22.2132 25.7478C22.8251 26.1306 23.4791 26.4418 24.1622 26.6754C24.8719 26.9965 25.6554 27.1198 26.4296 27.0321C26.8707 26.9654 27.2887 26.7916 27.6467 26.5258C28.0047 26.26 28.2917 25.9104 28.4826 25.5079C28.5948 25.2647 28.6288 24.9928 28.5801 24.7295C28.4631 24.1912 27.742 23.8734 27.3002 23.6139Z" fill="#006CC0"/>
                    </svg>
                  }
                />
                <SocialMedie
                  linkPath="https://msng.link/o?420732355845=vi"
                  theme='blue'
                  type = "mini"
                  icon = {
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="46"
                      fill="none"
                      viewBox="0 0 46 46"
                    >
                      <path
                        fill="#006CC0"
                        d="M17.632 32.403v-1.156a.117.117 0 00-.104-.136 6.682 6.682 0 01-2.605-1.415 6.262 6.262 0 01-1.343-1.724 9.363 9.363 0 01-.951-2.987 21.874 21.874 0 01-.189-3.943 19.42 19.42 0 01.115-1.836c.112-1.167.41-2.31.88-3.385a5.864 5.864 0 012.962-2.982c.903-.409 1.853-.708 2.828-.89.957-.179 1.926-.29 2.899-.33.877-.03 1.755-.017 2.63.04 1.254.052 2.496.257 3.698.612.892.254 1.74.64 2.517 1.143a5.373 5.373 0 011.778 2.025c.482.95.81 1.97.97 3.022.105.624.174 1.254.207 1.886.045.784.04 1.57.01 2.35s-.097 1.538-.194 2.302a8.476 8.476 0 01-1.046 3.264 6.061 6.061 0 01-3.328 2.704c-.96.334-1.953.571-2.962.709-.737.101-1.477.174-2.22.204-.569.026-1.138.02-1.706.01-.323 0-.643-.03-.964-.059a.164.164 0 00-.165.07 78.866 78.866 0 01-1.999 2.268 1.888 1.888 0 01-.424.35.796.796 0 01-1.194-.44c-.067-.21-.1-.43-.099-.65l-.001-1.026zm.783 1.464l.083-.076 1.47-1.618c.566-.622 1.131-1.245 1.696-1.869a.268.268 0 01.247-.108c.192.02.383.026.577.028.67 0 1.338 0 2.008-.047.607-.047 1.213-.12 1.815-.215.649-.111 1.29-.258 1.923-.437 1.409-.375 2.499-1.178 3.184-2.478.404-.8.658-1.665.748-2.554.107-.839.171-1.682.192-2.527a16.208 16.208 0 00-.218-3.17 8.912 8.912 0 00-.655-2.202c-.398-.872-.962-1.595-1.83-2.059-1.331-.712-2.771-1.029-4.256-1.191-.42-.046-.842-.07-1.264-.086a23.036 23.036 0 00-2.598.014c-.622.046-1.24.129-1.852.248-.9.162-1.77.457-2.583.872-.472.236-.9.55-1.265.927a5.81 5.81 0 00-1.271 2.284c-.29.986-.448 2.005-.47 3.032a22.779 22.779 0 00.038 3.081c.052.61.155 1.215.309 1.808a6.076 6.076 0 001.296 2.505 5.334 5.334 0 002.509 1.543c.121.036.176.078.176.214-.01.503 0 1.005 0 1.508l-.009 2.573z"
                      ></path>
                      <path
                        fill="#006CC0"
                        d="M17.183 18.283c-.012-.382.197-.66.45-.909a6.35 6.35 0 011.1-.835.887.887 0 011.139.184c.479.5.908 1.043 1.28 1.625.184.261.324.552.412.858a.69.69 0 01-.176.688c-.203.197-.42.38-.648.55a.744.744 0 00-.255.78c.14.659.46 1.268.924 1.761a5.35 5.35 0 002.113 1.548c.203.103.434.134.657.089a.603.603 0 00.31-.2c.123-.156.257-.306.368-.472a.98.98 0 011.331-.26c.597.343 1.16.74 1.684 1.187.123.104.252.206.375.312a.83.83 0 01.27.97 2.563 2.563 0 01-.47.775c-.249.344-.567.634-.934.851a1.311 1.311 0 01-1.17.075 15.483 15.483 0 01-3.95-2.301 15.383 15.383 0 01-3.028-3.315 13.726 13.726 0 01-1.673-3.334 4.211 4.211 0 01-.106-.435.919.919 0 01-.003-.192zM23.262 16.427a5.118 5.118 0 013.24 1.23 5.033 5.033 0 011.691 3.003c.077.424.118.853.123 1.284 0 .1-.026.174-.135.185-.11.01-.138-.082-.138-.175a5.796 5.796 0 00-.528-2.528 4.68 4.68 0 00-1.327-1.674 4.736 4.736 0 00-1.94-.917 5.238 5.238 0 00-1.142-.133.396.396 0 01-.111-.014.123.123 0 01-.086-.146.109.109 0 01.07-.101.111.111 0 01.044-.007c.08-.01.16-.007.24-.007z"
                      ></path>
                      <path
                        fill="#006CC0"
                        d="M26.662 21.159a3.08 3.08 0 00-.352-1.392 3.112 3.112 0 00-.946-1.086 3.346 3.346 0 00-1.564-.58c-.095-.013-.19-.018-.287-.029-.097-.01-.155-.05-.146-.148.009-.098.07-.133.176-.126 1.56.094 2.888.969 3.297 2.596.074.314.112.636.11.958v.087c0 .088-.056.145-.142.144a.14.14 0 01-.136-.152c-.014-.082-.01-.166-.01-.272z"
                      ></path>
                      <path
                        fill="#006CC0"
                        d="M23.928 19.215c.856.057 1.453.473 1.617 1.375.02.115.034.232.042.349 0 .094-.021.174-.125.174s-.139-.07-.143-.174c0-.346-.108-.683-.308-.967a1.306 1.306 0 00-.841-.468 1.912 1.912 0 00-.224-.027c-.123 0-.192-.061-.176-.156.016-.094.09-.111.158-.106z"
                      ></path>
                    </svg>
                  }
                />
            </div>
          </div>

          </div>


          <div className="footer__card-card" ref={elementRefs.card2}>
            <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94" fill="none">
              <path d="M62.6667 23.4999C62.6667 32.1524 55.6525 39.1666 47 39.1666C38.3475 39.1666 31.3333 32.1524 31.3333 23.4999C31.3333 14.8475 38.3475 7.83325 47 7.83325C55.6525 7.83325 62.6667 14.8475 62.6667 23.4999Z" fill="#006CC0"/>
              <path d="M61.4063 52.886C59.5534 52.9084 57.8264 52.976 56.3214 53.1784C53.8034 53.5169 51.0473 54.326 48.77 56.6033C46.4928 58.8806 45.6836 61.6367 45.3451 64.1546C45.0409 66.4174 45.0413 69.182 45.0417 72.1217V72.7951C45.0413 75.7347 45.0409 78.4993 45.3451 80.7621C45.5826 82.5289 46.0519 84.4131 47.0976 86.1667C47.0651 86.1667 47.0326 86.1667 47 86.1667C15.6667 86.1667 15.6667 78.2758 15.6667 68.5417C15.6667 58.8077 29.6951 50.9167 47 50.9167C52.1942 50.9167 57.0932 51.6277 61.4063 52.886Z" fill="#006CC0"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M64.625 86.1667C58.1628 86.1667 54.9317 86.1667 52.9242 84.1591C50.9167 82.1516 50.9167 78.9205 50.9167 72.4583C50.9167 65.9962 50.9167 62.7651 52.9242 60.7575C54.9317 58.75 58.1628 58.75 64.625 58.75C71.0872 58.75 74.3182 58.75 76.3258 60.7575C78.3333 62.7651 78.3333 65.9962 78.3333 72.4583C78.3333 78.9205 78.3333 82.1516 76.3258 84.1591C74.3182 86.1667 71.0872 86.1667 64.625 86.1667ZM72.3331 69.5044C73.2254 68.6122 73.2254 67.1656 72.3331 66.2733C71.4409 65.3811 69.9943 65.3811 69.102 66.2733L61.5787 73.7967L60.1479 72.3659C59.2557 71.4737 57.8091 71.4737 56.9169 72.3659C56.0246 73.2582 56.0246 74.7048 56.9169 75.597L59.9631 78.6433C60.8554 79.5356 62.302 79.5356 63.1942 78.6433L72.3331 69.5044Z" fill="#006CC0"/>
            </svg>

            <p className='footer__card-name'>{contactInfo.cart2.name}</p>
            <p className='footer__card-position'>{contactInfo.cart2.position}</p>
            <div className='footer__card-languages'>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                  <g clipPath="url(#clip0_801_901)">
                    <path d="M0 0.000244141H30V19.9998H0V0.000244141Z" fill="#FFDA44"/>
                    <path d="M0 0.000244141H30V10H0V0.000244141Z" fill="#338AF3"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_801_901">
                      <rect width="30" height="20" rx="2" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                  <g clipPath="url(#clip0_801_904)">
                    <path d="M0 -0.000244141V19.9997H30V-0.000244141H0Z" fill="white"/>
                    <path d="M0 0H30V20.0002H0V0Z" fill="#0052B4"/>
                    <path d="M0 0H30V6.6665H0V0Z" fill="#F6F6F6"/>
                    <path d="M0 13.333H30V19.9995H0V13.333Z" fill="#D80027"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_801_904">
                      <rect width="30" height="20" rx="2" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
            </div>


          <div className="footer__card-container">
            <div className="footer__card-box">
              <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#006CC0" strokeWidth="1.5" strokeMiterlimit="10"/>
              </svg>

              <a href="tel:+420 771 123 911" className='footer__card-text'>+420 771 123 911</a>
            </div>

            <div className="footer__card-box">
              <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#006CC0" strokeWidth="1.5" strokeMiterlimit="10"/>
              </svg>

              <a href="tel:+380 97 244 8383" className='footer__card-text'>+380 972 448 383</a>
            </div>

            <div className="footer__card-box">
              <svg  className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 11.8014C2 8.0926 2 6.2382 3.17157 5.08602C4.34315 3.93384 6.22876 3.93384 10 3.93384H14C17.7712 3.93384 19.6569 3.93384 20.8284 5.08602C22 6.2382 22 8.0926 22 11.8014C22 15.5102 22 17.3646 20.8284 18.5168C19.6569 19.669 17.7712 19.669 14 19.669H10C6.22876 19.669 4.34315 19.669 3.17157 18.5168C2 17.3646 2 15.5102 2 11.8014Z" stroke="#006CC0" strokeWidth="1.5"/>
                <path d="M6 7.86768L8.1589 9.63698C9.99553 11.1422 10.9139 11.8948 12 11.8948C13.0861 11.8948 14.0045 11.1422 15.8411 9.63697L18 7.86768" stroke="#006CC0" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>

              <a href="mailto:yevhen@okfg.cz" className='footer__card-text'>yevhen@okfg.cz</a>
            </div>

            <div className="footer__sociable">
              <SocialMedie
                  linkPath="https://t.me/Y3VG3NIY"
                  theme='blue'
                  type = "mini"
                  icon = {
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                      <path d="M12.7974 22.1852C12.7974 22.1852 22.156 18.2435 25.4017 16.8556C26.6459 16.3005 30.8653 14.5239 30.8653 14.5239C30.8653 14.5239 32.8128 13.7467 32.6505 15.6342C32.5964 16.4115 32.1636 19.1318 31.7309 22.0742C31.0817 26.238 30.3785 30.7903 30.3785 30.7903C30.3785 30.7903 30.2703 32.0673 29.3507 32.2893C28.4311 32.5114 26.9163 31.5121 26.6459 31.29C26.4295 31.1235 22.5887 28.6252 21.1822 27.4038C20.8035 27.0707 20.3708 26.4046 21.2363 25.6273C23.1838 23.7952 25.5099 21.519 26.9163 20.0756C27.5655 19.4094 28.2146 17.8549 25.5099 19.7425C21.6691 22.4628 17.8824 25.0166 17.8824 25.0166C17.8824 25.0166 17.0168 25.5717 15.394 25.0721C13.7711 24.5725 11.8777 23.9063 11.8777 23.9063C11.8777 23.9063 10.5795 23.0735 12.7974 22.1852Z" fill="#006CC0"/>
                    </svg>
                  }
                />
                <SocialMedie
                  linkPath="https://wa.me/380972448383"
                  theme='blue'
                  type = "mini"
                  icon = {
                    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                      <path d="M11.5001 33.6484L13.1503 27.5125C11.8195 25.1223 11.3967 22.3332 11.9594 19.6568C12.5222 16.9805 14.0329 14.5965 16.2142 12.9424C18.3955 11.2883 21.1011 10.475 23.8346 10.6517C26.5681 10.8284 29.146 11.9833 31.0954 13.9044C33.0449 15.8256 34.2349 18.3841 34.4473 21.1106C34.6596 23.837 33.88 26.5485 32.2515 28.7474C30.623 30.9463 28.2549 32.4851 25.5816 33.0816C22.9084 33.678 20.1093 33.292 17.6981 31.9944L11.5001 33.6484ZM17.997 29.6984L18.3803 29.9254C20.1268 30.9588 22.1666 31.3865 24.182 31.1417C26.1974 30.8969 28.075 29.9935 29.5223 28.5721C30.9697 27.1507 31.9054 25.2913 32.1837 23.2835C32.462 21.2758 32.0672 19.2326 31.0608 17.4722C30.0545 15.7119 28.4931 14.3333 26.6201 13.5514C24.747 12.7694 22.6675 12.628 20.7055 13.1492C18.7436 13.6703 17.0094 14.8248 15.7733 16.4327C14.5371 18.0405 13.8685 20.0114 13.8715 22.0383C13.8698 23.7189 14.3354 25.367 15.2163 26.7991L15.4567 27.1947L14.5341 30.6194L17.997 29.6984Z" fill="#006CC0"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M27.3002 23.6139C27.0755 23.433 26.8124 23.3056 26.5309 23.2416C26.2495 23.1775 25.9571 23.1784 25.676 23.2442C25.2537 23.4193 24.9808 24.0809 24.708 24.4117C24.6504 24.491 24.5659 24.5466 24.4702 24.5681C24.3745 24.5896 24.2742 24.5755 24.1882 24.5285C22.6422 23.9239 21.3463 22.8153 20.511 21.3827C20.4398 21.2933 20.406 21.1798 20.4169 21.0661C20.4279 20.9524 20.4825 20.8473 20.5695 20.773C20.8738 20.4722 21.0973 20.0997 21.2192 19.6898C21.2462 19.2378 21.1425 18.7875 20.9203 18.3926C20.7485 17.8391 20.4217 17.3462 19.9783 16.9722C19.7496 16.8695 19.496 16.8351 19.2482 16.873C19.0003 16.911 18.7688 17.0198 18.5814 17.1862C18.2563 17.4663 17.9981 17.8157 17.8261 18.2086C17.6541 18.6014 17.5725 19.0278 17.5874 19.4563C17.5884 19.697 17.619 19.9366 17.6784 20.1698C17.8292 20.7302 18.0612 21.2655 18.367 21.7589C18.5877 22.1369 18.8284 22.5028 19.0882 22.8551C19.9325 24.0122 20.9938 24.9946 22.2132 25.7478C22.8251 26.1306 23.4791 26.4418 24.1622 26.6754C24.8719 26.9965 25.6554 27.1198 26.4296 27.0321C26.8707 26.9654 27.2887 26.7916 27.6467 26.5258C28.0047 26.26 28.2917 25.9104 28.4826 25.5079C28.5948 25.2647 28.6288 24.9928 28.5801 24.7295C28.4631 24.1912 27.742 23.8734 27.3002 23.6139Z" fill="#006CC0"/>
                    </svg>
                  }
                />
                <SocialMedie
                  linkPath="https://msng.link/o?380972448383=vi"
                  theme='blue'
                  type = "mini"
                  icon = {
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="46"
                      height="46"
                      fill="none"
                      viewBox="0 0 46 46"
                    >
                      <path
                        fill="#006CC0"
                        d="M17.632 32.403v-1.156a.117.117 0 00-.104-.136 6.682 6.682 0 01-2.605-1.415 6.262 6.262 0 01-1.343-1.724 9.363 9.363 0 01-.951-2.987 21.874 21.874 0 01-.189-3.943 19.42 19.42 0 01.115-1.836c.112-1.167.41-2.31.88-3.385a5.864 5.864 0 012.962-2.982c.903-.409 1.853-.708 2.828-.89.957-.179 1.926-.29 2.899-.33.877-.03 1.755-.017 2.63.04 1.254.052 2.496.257 3.698.612.892.254 1.74.64 2.517 1.143a5.373 5.373 0 011.778 2.025c.482.95.81 1.97.97 3.022.105.624.174 1.254.207 1.886.045.784.04 1.57.01 2.35s-.097 1.538-.194 2.302a8.476 8.476 0 01-1.046 3.264 6.061 6.061 0 01-3.328 2.704c-.96.334-1.953.571-2.962.709-.737.101-1.477.174-2.22.204-.569.026-1.138.02-1.706.01-.323 0-.643-.03-.964-.059a.164.164 0 00-.165.07 78.866 78.866 0 01-1.999 2.268 1.888 1.888 0 01-.424.35.796.796 0 01-1.194-.44c-.067-.21-.1-.43-.099-.65l-.001-1.026zm.783 1.464l.083-.076 1.47-1.618c.566-.622 1.131-1.245 1.696-1.869a.268.268 0 01.247-.108c.192.02.383.026.577.028.67 0 1.338 0 2.008-.047.607-.047 1.213-.12 1.815-.215.649-.111 1.29-.258 1.923-.437 1.409-.375 2.499-1.178 3.184-2.478.404-.8.658-1.665.748-2.554.107-.839.171-1.682.192-2.527a16.208 16.208 0 00-.218-3.17 8.912 8.912 0 00-.655-2.202c-.398-.872-.962-1.595-1.83-2.059-1.331-.712-2.771-1.029-4.256-1.191-.42-.046-.842-.07-1.264-.086a23.036 23.036 0 00-2.598.014c-.622.046-1.24.129-1.852.248-.9.162-1.77.457-2.583.872-.472.236-.9.55-1.265.927a5.81 5.81 0 00-1.271 2.284c-.29.986-.448 2.005-.47 3.032a22.779 22.779 0 00.038 3.081c.052.61.155 1.215.309 1.808a6.076 6.076 0 001.296 2.505 5.334 5.334 0 002.509 1.543c.121.036.176.078.176.214-.01.503 0 1.005 0 1.508l-.009 2.573z"
                      ></path>
                      <path
                        fill="#006CC0"
                        d="M17.183 18.283c-.012-.382.197-.66.45-.909a6.35 6.35 0 011.1-.835.887.887 0 011.139.184c.479.5.908 1.043 1.28 1.625.184.261.324.552.412.858a.69.69 0 01-.176.688c-.203.197-.42.38-.648.55a.744.744 0 00-.255.78c.14.659.46 1.268.924 1.761a5.35 5.35 0 002.113 1.548c.203.103.434.134.657.089a.603.603 0 00.31-.2c.123-.156.257-.306.368-.472a.98.98 0 011.331-.26c.597.343 1.16.74 1.684 1.187.123.104.252.206.375.312a.83.83 0 01.27.97 2.563 2.563 0 01-.47.775c-.249.344-.567.634-.934.851a1.311 1.311 0 01-1.17.075 15.483 15.483 0 01-3.95-2.301 15.383 15.383 0 01-3.028-3.315 13.726 13.726 0 01-1.673-3.334 4.211 4.211 0 01-.106-.435.919.919 0 01-.003-.192zM23.262 16.427a5.118 5.118 0 013.24 1.23 5.033 5.033 0 011.691 3.003c.077.424.118.853.123 1.284 0 .1-.026.174-.135.185-.11.01-.138-.082-.138-.175a5.796 5.796 0 00-.528-2.528 4.68 4.68 0 00-1.327-1.674 4.736 4.736 0 00-1.94-.917 5.238 5.238 0 00-1.142-.133.396.396 0 01-.111-.014.123.123 0 01-.086-.146.109.109 0 01.07-.101.111.111 0 01.044-.007c.08-.01.16-.007.24-.007z"
                      ></path>
                      <path
                        fill="#006CC0"
                        d="M26.662 21.159a3.08 3.08 0 00-.352-1.392 3.112 3.112 0 00-.946-1.086 3.346 3.346 0 00-1.564-.58c-.095-.013-.19-.018-.287-.029-.097-.01-.155-.05-.146-.148.009-.098.07-.133.176-.126 1.56.094 2.888.969 3.297 2.596.074.314.112.636.11.958v.087c0 .088-.056.145-.142.144a.14.14 0 01-.136-.152c-.014-.082-.01-.166-.01-.272z"
                      ></path>
                      <path
                        fill="#006CC0"
                        d="M23.928 19.215c.856.057 1.453.473 1.617 1.375.02.115.034.232.042.349 0 .094-.021.174-.125.174s-.139-.07-.143-.174c0-.346-.108-.683-.308-.967a1.306 1.306 0 00-.841-.468 1.912 1.912 0 00-.224-.027c-.123 0-.192-.061-.176-.156.016-.094.09-.111.158-.106z"
                      ></path>
                    </svg>
                  }
                />
            </div>
          </div>
          </div>


          <div className="footer__card-card" ref={elementRefs.card3}>
            <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="94" height="94" viewBox="0 0 94 94" fill="none">
              <path d="M62.6667 23.4999C62.6667 32.1524 55.6525 39.1666 47 39.1666C38.3475 39.1666 31.3333 32.1524 31.3333 23.4999C31.3333 14.8475 38.3475 7.83325 47 7.83325C55.6525 7.83325 62.6667 14.8475 62.6667 23.4999Z" fill="#006CC0"/>
              <path d="M61.4063 52.886C59.5534 52.9084 57.8264 52.976 56.3214 53.1784C53.8034 53.5169 51.0473 54.326 48.77 56.6033C46.4928 58.8806 45.6836 61.6367 45.3451 64.1546C45.0409 66.4174 45.0413 69.182 45.0417 72.1217V72.7951C45.0413 75.7347 45.0409 78.4993 45.3451 80.7621C45.5826 82.5289 46.0519 84.4131 47.0976 86.1667C47.0651 86.1667 47.0326 86.1667 47 86.1667C15.6667 86.1667 15.6667 78.2758 15.6667 68.5417C15.6667 58.8077 29.6951 50.9167 47 50.9167C52.1942 50.9167 57.0932 51.6277 61.4063 52.886Z" fill="#006CC0"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M64.625 86.1667C58.1628 86.1667 54.9317 86.1667 52.9242 84.1591C50.9167 82.1516 50.9167 78.9205 50.9167 72.4583C50.9167 65.9962 50.9167 62.7651 52.9242 60.7575C54.9317 58.75 58.1628 58.75 64.625 58.75C71.0872 58.75 74.3182 58.75 76.3258 60.7575C78.3333 62.7651 78.3333 65.9962 78.3333 72.4583C78.3333 78.9205 78.3333 82.1516 76.3258 84.1591C74.3182 86.1667 71.0872 86.1667 64.625 86.1667ZM72.3331 69.5044C73.2254 68.6122 73.2254 67.1656 72.3331 66.2733C71.4409 65.3811 69.9943 65.3811 69.102 66.2733L61.5787 73.7967L60.1479 72.3659C59.2557 71.4737 57.8091 71.4737 56.9169 72.3659C56.0246 73.2582 56.0246 74.7048 56.9169 75.597L59.9631 78.6433C60.8554 79.5356 62.302 79.5356 63.1942 78.6433L72.3331 69.5044Z" fill="#006CC0"/>
            </svg>

            <p className='footer__card-name'>{contactInfo.cart3.name}</p>
            <p className='footer__card-position'>{contactInfo.cart3.position}</p>
            <div className='footer__card-languages'>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                <g clipPath="url(#clip0_801_1011)">
                  <path d="M0 0H30V19.9041H0V0Z" fill="#F6F6F6"/>
                  <path d="M16.875 -0.000244141H13.125V8.08566H0V11.8176H13.125V19.9035H16.875V11.8176H30V8.08566H16.875V-0.000244141Z" fill="#D80027"/>
                  <path d="M23.0733 13.4133L30 17.243V13.4133H23.0733ZM18.2609 13.4133L30 19.9037V18.0684L21.5805 13.4133H18.2609ZM26.8731 19.9037L18.2609 15.1417V19.9037H26.8731Z" fill="#0052B4"/>
                  <path d="M18.2609 13.4133L30 19.9037V18.0684L21.5805 13.4133H18.2609Z" fill="#F6F6F6"/>
                  <path d="M18.2609 13.4133L30 19.9037V18.0684L21.5805 13.4133H18.2609Z" fill="#D80027"/>
                  <path d="M5.29342 13.4131L0 16.3398V13.4131H5.29342ZM11.7391 14.2384V19.9035H1.49361L11.7391 14.2384Z" fill="#0052B4"/>
                  <path d="M8.41951 13.4133L0 18.0684V19.9037L11.7391 13.4133H8.41951Z" fill="#D80027"/>
                  <path d="M6.92666 6.49013L0 2.66042V6.49013H6.92666ZM11.7391 6.49013L0 -0.000244141V1.83507L8.41951 6.49013H11.7391ZM3.12691 -0.000244141L11.7391 4.76176V-0.000244141H3.12691Z" fill="#0052B4"/>
                  <path d="M11.7391 6.49013L0 -0.000244141V1.83507L8.41951 6.49013H11.7391Z" fill="#F6F6F6"/>
                  <path d="M11.7391 6.49013L0 -0.000244141V1.83507L8.41951 6.49013H11.7391Z" fill="#D80027"/>
                  <path d="M24.7066 6.49043L30 3.56374V6.49043H24.7066ZM18.2609 5.66508V0H28.5064L18.2609 5.66508Z" fill="#0052B4"/>
                  <path d="M21.5805 6.49013L30 1.83507V-0.000244141L18.2609 6.49013H21.5805Z" fill="#D80027"/>
                </g>
                <defs>
                  <clipPath id="clip0_801_1011">
                    <rect width="30" height="19.9038" rx="3" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                <g clipPath="url(#clip0_801_901)">
                  <path d="M0 0.000244141H30V19.9998H0V0.000244141Z" fill="#FFDA44"/>
                  <path d="M0 0.000244141H30V10H0V0.000244141Z" fill="#338AF3"/>
                </g>
                <defs>
                  <clipPath id="clip0_801_901">
                    <rect width="30" height="20" rx="2" fill="white"/>
                  </clipPath>
                </defs>
              </svg>

              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
                <g clipPath="url(#clip0_801_904)">
                  <path d="M0 -0.000244141V19.9997H30V-0.000244141H0Z" fill="white"/>
                  <path d="M0 0H30V20.0002H0V0Z" fill="#0052B4"/>
                  <path d="M0 0H30V6.6665H0V0Z" fill="#F6F6F6"/>
                  <path d="M0 13.333H30V19.9995H0V13.333Z" fill="#D80027"/>
                </g>
                <defs>
                  <clipPath id="clip0_801_904">
                    <rect width="30" height="20" rx="2" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
          </div>


            <div className="footer__card-container">
              <div className="footer__card-box">
                <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="#006CC0" strokeWidth="1.5" strokeMiterlimit="10"/>
                </svg>

                <a href="tel:+380 985 900 462" className='footer__card-text'>+380 985 900 462</a>
              </div>

              <div className="footer__card-box">
                <svg  className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M2 11.8014C2 8.0926 2 6.2382 3.17157 5.08602C4.34315 3.93384 6.22876 3.93384 10 3.93384H14C17.7712 3.93384 19.6569 3.93384 20.8284 5.08602C22 6.2382 22 8.0926 22 11.8014C22 15.5102 22 17.3646 20.8284 18.5168C19.6569 19.669 17.7712 19.669 14 19.669H10C6.22876 19.669 4.34315 19.669 3.17157 18.5168C2 17.3646 2 15.5102 2 11.8014Z" stroke="#006CC0" strokeWidth="1.5"/>
                  <path d="M6 7.86768L8.1589 9.63698C9.99553 11.1422 10.9139 11.8948 12 11.8948C13.0861 11.8948 14.0045 11.1422 15.8411 9.63697L18 7.86768" stroke="#006CC0" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>

                <a href="mailto:natalia@okfg.cz" className='footer__card-text'>natalia@okfg.cz</a>
              </div>

              <div className="footer__sociable">
                <SocialMedie
                    linkPath="https://t.me/nataliiiiok"
                    theme='blue'
                    type = "mini"
                    icon = {
                      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M12.7974 22.1852C12.7974 22.1852 22.156 18.2435 25.4017 16.8556C26.6459 16.3005 30.8653 14.5239 30.8653 14.5239C30.8653 14.5239 32.8128 13.7467 32.6505 15.6342C32.5964 16.4115 32.1636 19.1318 31.7309 22.0742C31.0817 26.238 30.3785 30.7903 30.3785 30.7903C30.3785 30.7903 30.2703 32.0673 29.3507 32.2893C28.4311 32.5114 26.9163 31.5121 26.6459 31.29C26.4295 31.1235 22.5887 28.6252 21.1822 27.4038C20.8035 27.0707 20.3708 26.4046 21.2363 25.6273C23.1838 23.7952 25.5099 21.519 26.9163 20.0756C27.5655 19.4094 28.2146 17.8549 25.5099 19.7425C21.6691 22.4628 17.8824 25.0166 17.8824 25.0166C17.8824 25.0166 17.0168 25.5717 15.394 25.0721C13.7711 24.5725 11.8777 23.9063 11.8777 23.9063C11.8777 23.9063 10.5795 23.0735 12.7974 22.1852Z" fill="#006CC0"/>
                      </svg>
                    }
                  />
                  <SocialMedie
                    linkPath="https://wa.me/380985900462"
                    theme='blue'
                    type = "mini"
                    icon = {
                      <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M11.5001 33.6484L13.1503 27.5125C11.8195 25.1223 11.3967 22.3332 11.9594 19.6568C12.5222 16.9805 14.0329 14.5965 16.2142 12.9424C18.3955 11.2883 21.1011 10.475 23.8346 10.6517C26.5681 10.8284 29.146 11.9833 31.0954 13.9044C33.0449 15.8256 34.2349 18.3841 34.4473 21.1106C34.6596 23.837 33.88 26.5485 32.2515 28.7474C30.623 30.9463 28.2549 32.4851 25.5816 33.0816C22.9084 33.678 20.1093 33.292 17.6981 31.9944L11.5001 33.6484ZM17.997 29.6984L18.3803 29.9254C20.1268 30.9588 22.1666 31.3865 24.182 31.1417C26.1974 30.8969 28.075 29.9935 29.5223 28.5721C30.9697 27.1507 31.9054 25.2913 32.1837 23.2835C32.462 21.2758 32.0672 19.2326 31.0608 17.4722C30.0545 15.7119 28.4931 14.3333 26.6201 13.5514C24.747 12.7694 22.6675 12.628 20.7055 13.1492C18.7436 13.6703 17.0094 14.8248 15.7733 16.4327C14.5371 18.0405 13.8685 20.0114 13.8715 22.0383C13.8698 23.7189 14.3354 25.367 15.2163 26.7991L15.4567 27.1947L14.5341 30.6194L17.997 29.6984Z" fill="#006CC0"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M27.3002 23.6139C27.0755 23.433 26.8124 23.3056 26.5309 23.2416C26.2495 23.1775 25.9571 23.1784 25.676 23.2442C25.2537 23.4193 24.9808 24.0809 24.708 24.4117C24.6504 24.491 24.5659 24.5466 24.4702 24.5681C24.3745 24.5896 24.2742 24.5755 24.1882 24.5285C22.6422 23.9239 21.3463 22.8153 20.511 21.3827C20.4398 21.2933 20.406 21.1798 20.4169 21.0661C20.4279 20.9524 20.4825 20.8473 20.5695 20.773C20.8738 20.4722 21.0973 20.0997 21.2192 19.6898C21.2462 19.2378 21.1425 18.7875 20.9203 18.3926C20.7485 17.8391 20.4217 17.3462 19.9783 16.9722C19.7496 16.8695 19.496 16.8351 19.2482 16.873C19.0003 16.911 18.7688 17.0198 18.5814 17.1862C18.2563 17.4663 17.9981 17.8157 17.8261 18.2086C17.6541 18.6014 17.5725 19.0278 17.5874 19.4563C17.5884 19.697 17.619 19.9366 17.6784 20.1698C17.8292 20.7302 18.0612 21.2655 18.367 21.7589C18.5877 22.1369 18.8284 22.5028 19.0882 22.8551C19.9325 24.0122 20.9938 24.9946 22.2132 25.7478C22.8251 26.1306 23.4791 26.4418 24.1622 26.6754C24.8719 26.9965 25.6554 27.1198 26.4296 27.0321C26.8707 26.9654 27.2887 26.7916 27.6467 26.5258C28.0047 26.26 28.2917 25.9104 28.4826 25.5079C28.5948 25.2647 28.6288 24.9928 28.5801 24.7295C28.4631 24.1912 27.742 23.8734 27.3002 23.6139Z" fill="#006CC0"/>
                      </svg>
                    }
                  />
                  <SocialMedie
                    linkPath="https://msng.link/o?380985900462=vi"
                    theme='blue'
                    type = "mini"
                    icon = {
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="46"
                        height="46"
                        fill="none"
                        viewBox="0 0 46 46"
                      >
                        <path
                          fill="#006CC0"
                          d="M17.632 32.403v-1.156a.117.117 0 00-.104-.136 6.682 6.682 0 01-2.605-1.415 6.262 6.262 0 01-1.343-1.724 9.363 9.363 0 01-.951-2.987 21.874 21.874 0 01-.189-3.943 19.42 19.42 0 01.115-1.836c.112-1.167.41-2.31.88-3.385a5.864 5.864 0 012.962-2.982c.903-.409 1.853-.708 2.828-.89.957-.179 1.926-.29 2.899-.33.877-.03 1.755-.017 2.63.04 1.254.052 2.496.257 3.698.612.892.254 1.74.64 2.517 1.143a5.373 5.373 0 011.778 2.025c.482.95.81 1.97.97 3.022.105.624.174 1.254.207 1.886.045.784.04 1.57.01 2.35s-.097 1.538-.194 2.302a8.476 8.476 0 01-1.046 3.264 6.061 6.061 0 01-3.328 2.704c-.96.334-1.953.571-2.962.709-.737.101-1.477.174-2.22.204-.569.026-1.138.02-1.706.01-.323 0-.643-.03-.964-.059a.164.164 0 00-.165.07 78.866 78.866 0 01-1.999 2.268 1.888 1.888 0 01-.424.35.796.796 0 01-1.194-.44c-.067-.21-.1-.43-.099-.65l-.001-1.026zm.783 1.464l.083-.076 1.47-1.618c.566-.622 1.131-1.245 1.696-1.869a.268.268 0 01.247-.108c.192.02.383.026.577.028.67 0 1.338 0 2.008-.047.607-.047 1.213-.12 1.815-.215.649-.111 1.29-.258 1.923-.437 1.409-.375 2.499-1.178 3.184-2.478.404-.8.658-1.665.748-2.554.107-.839.171-1.682.192-2.527a16.208 16.208 0 00-.218-3.17 8.912 8.912 0 00-.655-2.202c-.398-.872-.962-1.595-1.83-2.059-1.331-.712-2.771-1.029-4.256-1.191-.42-.046-.842-.07-1.264-.086a23.036 23.036 0 00-2.598.014c-.622.046-1.24.129-1.852.248-.9.162-1.77.457-2.583.872-.472.236-.9.55-1.265.927a5.81 5.81 0 00-1.271 2.284c-.29.986-.448 2.005-.47 3.032a22.779 22.779 0 00.038 3.081c.052.61.155 1.215.309 1.808a6.076 6.076 0 001.296 2.505 5.334 5.334 0 002.509 1.543c.121.036.176.078.176.214-.01.503 0 1.005 0 1.508l-.009 2.573z"
                        ></path>
                        <path
                          fill="#006CC0"
                          d="M17.183 18.283c-.012-.382.197-.66.45-.909a6.35 6.35 0 011.1-.835.887.887 0 011.139.184c.479.5.908 1.043 1.28 1.625.184.261.324.552.412.858a.69.69 0 01-.176.688c-.203.197-.42.38-.648.55a.744.744 0 00-.255.78c.14.659.46 1.268.924 1.761a5.35 5.35 0 002.113 1.548c.203.103.434.134.657.089a.603.603 0 00.31-.2c.123-.156.257-.306.368-.472a.98.98 0 011.331-.26c.597.343 1.16.74 1.684 1.187.123.104.252.206.375.312a.83.83 0 01.27.97 2.563 2.563 0 01-.47.775c-.249.344-.567.634-.934.851a1.311 1.311 0 01-1.17.075 15.483 15.483 0 01-3.95-2.301 15.383 15.383 0 01-3.028-3.315 13.726 13.726 0 01-1.673-3.334 4.211 4.211 0 01-.106-.435.919.919 0 01-.003-.192zM23.262 16.427a5.118 5.118 0 013.24 1.23 5.033 5.033 0 011.691 3.003c.077.424.118.853.123 1.284 0 .1-.026.174-.135.185-.11.01-.138-.082-.138-.175a5.796 5.796 0 00-.528-2.528 4.68 4.68 0 00-1.327-1.674 4.736 4.736 0 00-1.94-.917 5.238 5.238 0 00-1.142-.133.396.396 0 01-.111-.014.123.123 0 01-.086-.146.109.109 0 01.07-.101.111.111 0 01.044-.007c.08-.01.16-.007.24-.007z"
                        ></path>
                        <path
                          fill="#006CC0"
                          d="M26.662 21.159a3.08 3.08 0 00-.352-1.392 3.112 3.112 0 00-.946-1.086 3.346 3.346 0 00-1.564-.58c-.095-.013-.19-.018-.287-.029-.097-.01-.155-.05-.146-.148.009-.098.07-.133.176-.126 1.56.094 2.888.969 3.297 2.596.074.314.112.636.11.958v.087c0 .088-.056.145-.142.144a.14.14 0 01-.136-.152c-.014-.082-.01-.166-.01-.272z"
                        ></path>
                        <path
                          fill="#006CC0"
                          d="M23.928 19.215c.856.057 1.453.473 1.617 1.375.02.115.034.232.042.349 0 .094-.021.174-.125.174s-.139-.07-.143-.174c0-.346-.108-.683-.308-.967a1.306 1.306 0 00-.841-.468 1.912 1.912 0 00-.224-.027c-.123 0-.192-.061-.176-.156.016-.094.09-.111.158-.106z"
                        ></path>
                      </svg>
                    }
                  />
              </div>
            </div>
          </div>

          <div className="footer__card-card footer__card-card--last" ref={elementRefs.card4}>
            <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 94 94" fill="none">
              <circle cx="47" cy="47" r="37" fill="#006CC0"/>
              <path d="M22.4993 50.75H27.7754C27.5991 49.5076 27.5064 48.2548 27.4979 47C27.5064 45.7452 27.5991 44.4924 27.7754 43.25H22.4993C21.8365 43.25 21.2007 42.9866 20.732 42.5178C20.2633 42.0489 20 41.413 20 40.75C20 40.087 20.2633 39.4511 20.732 38.9822C21.2007 38.5134 21.8365 38.25 22.4993 38.25H28.925C30.2278 33.7058 32.9309 29.6888 36.6493 26.771C40.3678 23.8533 44.9119 22.1836 49.6343 22C52.751 21.999 55.8244 22.7303 58.6068 24.135C59.2001 24.4314 59.6513 24.9514 59.8613 25.5805C60.0713 26.2097 60.0228 26.8966 59.7265 27.49C59.4302 28.0834 58.9104 28.5348 58.2814 28.7448C57.6524 28.9549 56.9657 28.9064 56.3725 28.61C54.2835 27.5529 51.9754 27.0014 49.6343 27C46.2441 27.1407 42.9719 28.285 40.2324 30.2878C37.493 32.2905 35.4095 35.0617 34.2461 38.25H47.4924C48.1553 38.25 48.791 38.5134 49.2597 38.9822C49.7284 39.4511 49.9917 40.087 49.9917 40.75C49.9917 41.413 49.7284 42.0489 49.2597 42.5178C48.791 42.9866 48.1553 43.25 47.4924 43.25H32.809C32.3924 45.7326 32.3924 48.2674 32.809 50.75H44.9931C45.656 50.75 46.2917 51.0134 46.7604 51.4822C47.2291 51.9511 47.4924 52.587 47.4924 53.25C47.4924 53.913 47.2291 54.5489 46.7604 55.0178C46.2917 55.4866 45.656 55.75 44.9931 55.75H34.2461C35.4095 58.9384 37.493 61.7095 40.2324 63.7122C42.9719 65.715 46.2441 66.8593 49.6343 67C51.9788 67.0002 54.2904 66.4487 56.3825 65.39C56.9757 65.0936 57.6624 65.0451 58.2914 65.2552C58.9204 65.4652 59.4402 65.9166 59.7365 66.51C60.0328 67.1034 60.0813 67.7903 59.8713 68.4195C59.6614 69.0486 59.2101 69.5686 58.6168 69.865C55.8344 71.2697 52.761 72.001 49.6443 72C44.9219 71.8164 40.3778 70.1467 36.6593 67.229C32.9409 64.3112 30.2378 60.2942 28.935 55.75H22.4993C21.8365 55.75 21.2007 55.4866 20.732 55.0178C20.2633 54.5489 20 53.913 20 53.25C20 52.587 20.2633 51.9511 20.732 51.4822C21.2007 51.0134 21.8365 50.75 22.4993 50.75Z" fill="white"/>
            </svg>

            <div className="footer__card-container footer__card-container--info">
              <p className="footer__card-box__description">{contactInfo.cart4.sales}</p>

              <div className="footer__card-box">
                <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M2 11.8014C2 8.0926 2 6.2382 3.17157 5.08602C4.34315 3.93384 6.22876 3.93384 10 3.93384H14C17.7712 3.93384 19.6569 3.93384 20.8284 5.08602C22 6.2382 22 8.0926 22 11.8014C22 15.5102 22 17.3646 20.8284 18.5168C19.6569 19.669 17.7712 19.669 14 19.669H10C6.22876 19.669 4.34315 19.669 3.17157 18.5168C2 17.3646 2 15.5102 2 11.8014Z" stroke="#006CC0" strokeWidth="1.5"/>
                  <path d="M6 7.86768L8.1589 9.63698C9.99553 11.1422 10.9139 11.8948 12 11.8948C13.0861 11.8948 14.0045 11.1422 15.8411 9.63697L18 7.86768" stroke="#006CC0" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>

                <a href='mailto:sales@okfg.cz' className='footer__card-text'>sales@okfg.cz</a>
              </div>

              <p className="footer__card-box__description footer__card-box__description--info">{contactInfo.cart4.invoicing}</p>

              <div className="footer__card-box">
                <svg className='footer__card-img' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 11.8014C2 8.0926 2 6.2382 3.17157 5.08602C4.34315 3.93384 6.22876 3.93384 10 3.93384H14C17.7712 3.93384 19.6569 3.93384 20.8284 5.08602C22 6.2382 22 8.0926 22 11.8014C22 15.5102 22 17.3646 20.8284 18.5168C19.6569 19.669 17.7712 19.669 14 19.669H10C6.22876 19.669 4.34315 19.669 3.17157 18.5168C2 17.3646 2 15.5102 2 11.8014Z" stroke="#006CC0" strokeWidth="1.5"/>
                <path d="M6 7.86768L8.1589 9.63698C9.99553 11.1422 10.9139 11.8948 12 11.8948C13.0861 11.8948 14.0045 11.1422 15.8411 9.63697L18 7.86768" stroke="#006CC0" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>

                <a href='mailto:info@okfg.cz' className='footer__card-text'>info@okfg.cz</a>
              </div>

            </div>
          </div>
      </div>


      <footer className='footer'>
        <div className="footer__row">

          <div className="footer__info" ref={elementRefs.column1}>
            <a href="#!" className="footer__logo">
              <img width={"100px"} height={"50px"} src={Logo} alt="Logo" />
            </a>

            <p className="footer__info-description">
              {contactInfo.description}
            </p>
          </div>


          <ul className="footer__list" ref={elementRefs.column2}>
            <p className="footer__title">
              {contactInfo.titleNav}
            </p>
            
            <li  onClick={() => handleScrollToSection('about-us')} className="footer__item ">
              <p className="footer__link">{contactInfo.nav1}</p>
            </li>
            <li onClick={() => handleScrollToSection('service')} className="footer__item">
              <p  className="footer__link">{contactInfo.nav2}</p>
            </li>
            <li onClick={() => handleScrollToSection('work')} className="footer__item">
              <p className="footer__link">{contactInfo.nav3}</p>
            </li>
            <li onClick={() => handleScrollToSection('faq')} className="footer__item">
              <p className="footer__link">{contactInfo.nav4}</p>
            </li>
            <li onClick={() => handleScrollToSection('contact')} className="footer__item">
              <p className="footer__link">{contactInfo.nav5}</p>
            </li>           
          </ul>
          
          <ul className="footer__list" ref={elementRefs.column3}>
            <h3 className="footer__title">
              {contactInfo.titleNav2}
            </h3>

            <li className="footer__item">
              <svg className='footer__item-company' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5833 36.2613H4.5V12.5113C4.5 10.3446 5.83334 8.34461 7.91667 7.51128L18.4167 3.26128C20.3333 2.51128 22.5 3.42794 23.25 5.34461C23.4167 5.76128 23.5 6.26128 23.5 6.76128V36.2613H23.5833ZM7 33.7613H21.0833V6.67794C21.0833 6.01128 20.5 5.42794 19.8333 5.42794C19.6667 5.42794 19.5 5.42794 19.3333 5.51128L8.75 9.76132C7.75 10.2613 7 11.2613 7 12.5113V33.7613Z" fill="white"/>
                <path d="M36.167 36.2614H22.5003V33.7614H33.667V19.8447C33.667 18.5114 32.7503 17.3447 31.5003 17.0114L22.167 14.5114L22.8337 12.0947L32.167 14.5947C34.5003 15.2614 36.167 17.3447 36.167 19.8447V36.2614Z" fill="white"/>
                <path d="M37.4167 36.2614H3.25C2.58334 36.2614 2 35.678 2 35.0114C2 34.2614 2.58334 33.7614 3.25 33.7614H37.4167C38.0833 33.7614 38.6667 34.3447 38.6667 35.0114C38.6667 35.678 38.0833 36.2614 37.4167 36.2614ZM16.1667 15.3447H12C11.3333 15.3447 10.75 14.7614 10.75 14.0947C10.75 13.428 11.3333 12.8447 12 12.8447H16.1667C16.8333 12.8447 17.4167 13.428 17.4167 14.0947C17.4167 14.7614 16.8333 15.3447 16.1667 15.3447ZM16.1667 21.678H12C11.3333 21.678 10.75 21.0947 10.75 20.428C10.75 19.7614 11.3333 19.178 12 19.178H16.1667C16.8333 19.178 17.4167 19.7614 17.4167 20.428C17.4167 21.0947 16.8333 21.678 16.1667 21.678ZM16.1667 28.0114H12C11.3333 28.0114 10.75 27.428 10.75 26.7614C10.75 26.0947 11.3333 25.5114 12 25.5114H16.1667C16.8333 25.5114 17.4167 26.0947 17.4167 26.7614C17.4167 27.428 16.8333 28.0114 16.1667 28.0114ZM30 28.0114H27.5C26.8333 28.0114 26.25 27.428 26.25 26.7614C26.25 26.0947 26.8333 25.5114 27.5 25.5114H30C30.6667 25.5114 31.25 26.0947 31.25 26.7614C31.25 27.428 30.6667 28.0114 30 28.0114Z" fill="white"/>
              </svg>



              <a href="#!" className="footer__link">OKFG s.r.o.</a>
            </li>

            <li className="footer__item">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="10" stroke="#fff" strokeWidth="1.5"/>
                <path d="M8.5 12.5L10.5 14.5L15.5 9.5" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>

              <a href="#!" className="footer__link">{contactInfo.cin}: 06489435 </a>
            </li>

            <li className="footer__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M12 14.0896C13.7231 14.0896 15.12 12.7158 15.12 11.0212C15.12 9.32663 13.7231 7.95288 12 7.95288C10.2769 7.95288 8.88 9.32663 8.88 11.0212C8.88 12.7158 10.2769 14.0896 12 14.0896Z" stroke="white" strokeWidth="1.5"/>
                <path d="M3.62001 9.2312C5.59001 0.714555 18.42 0.72439 20.38 9.24104C21.53 14.2369 18.37 18.4658 15.6 21.0817C13.59 22.9896 10.41 22.9896 8.39002 21.0817C5.63001 18.4658 2.47001 14.2271 3.62001 9.2312Z" stroke="white" strokeWidth="1.5"/>
              </svg>
              <a href="https://maps.app.goo.gl/Yotf9dygSEHvdQDM9" target='_blank' className="footer__link">{contactInfo.address}</a>
            </li>
          </ul>

          <ul className="footer__list" ref={elementRefs.column4}>
            <p className="footer__title">
              {contactInfo.titleNav3}
            </p>


            <li className="footer__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M8 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16 2V5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.5 9.08997H20.5" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.6947 13.7H15.7037" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.6947 16.7H15.7037" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.9955 13.7H12.0045" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.9955 16.7H12.0045" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.29431 13.7H8.30329" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.29431 16.7H8.30329" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
                <a href="#!" className="footer__link">{contactInfo.workSchedule}: 8:00-18:00</a>
            </li>

            <li className="footer__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.6 18.45 8.51 17.52 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10"/>
              </svg>
              <a href="tel:+420 732 355 845" className="footer__link">+420 732 355 845</a>
            </li>

            <li className="footer__item">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M2 12.5316C2 8.82283 2 6.96842 3.17157 5.81624C4.34315 4.66406 6.22876 4.66406 10 4.66406H14C17.7712 4.66406 19.6569 4.66406 20.8284 5.81624C22 6.96842 22 8.82283 22 12.5316C22 16.2404 22 18.0949 20.8284 19.247C19.6569 20.3992 17.7712 20.3992 14 20.3992H10C6.22876 20.3992 4.34315 20.3992 3.17157 19.247C2 18.0949 2 16.2404 2 12.5316Z" stroke="white" strokeWidth="1.5"/>
                <path d="M6 8.5979L8.1589 10.3672C9.99553 11.8724 10.9139 12.625 12 12.625C13.0861 12.625 14.0045 11.8724 15.8411 10.3672L18 8.5979" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
              </svg>
              <a href="mailto:info@okfg.cz" className="footer__link">info@okfg.cz</a>
            </li>

            <li className="footer__social-media">
              <SocialMedie
                linkPath="https://t.me/andriiokfg"
                theme='white'
                type= "mini"
                icon = {
                  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                    <path d="M12.7974 22.1852C12.7974 22.1852 22.156 18.2435 25.4017 16.8556C26.6459 16.3005 30.8653 14.5239 30.8653 14.5239C30.8653 14.5239 32.8128 13.7467 32.6505 15.6342C32.5964 16.4115 32.1636 19.1318 31.7309 22.0742C31.0817 26.238 30.3785 30.7903 30.3785 30.7903C30.3785 30.7903 30.2703 32.0673 29.3507 32.2893C28.4311 32.5114 26.9163 31.5121 26.6459 31.29C26.4295 31.1235 22.5887 28.6252 21.1822 27.4038C20.8035 27.0707 20.3708 26.4046 21.2363 25.6273C23.1838 23.7952 25.5099 21.519 26.9163 20.0756C27.5655 19.4094 28.2146 17.8549 25.5099 19.7425C21.6691 22.4628 17.8824 25.0166 17.8824 25.0166C17.8824 25.0166 17.0168 25.5717 15.394 25.0721C13.7711 24.5725 11.8777 23.9063 11.8777 23.9063C11.8777 23.9063 10.5795 23.0735 12.7974 22.1852Z" fill="#006CC0"/>
                  </svg>
                }
              />
              <SocialMedie
                linkPath="https://wa.me/420732355845"
                theme='white'
                type= "mini"
                icon = {
                  <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                    <path d="M11.5001 33.6484L13.1503 27.5125C11.8195 25.1223 11.3967 22.3332 11.9594 19.6568C12.5222 16.9805 14.0329 14.5965 16.2142 12.9424C18.3955 11.2883 21.1011 10.475 23.8346 10.6517C26.5681 10.8284 29.146 11.9833 31.0954 13.9044C33.0449 15.8256 34.2349 18.3841 34.4473 21.1106C34.6596 23.837 33.88 26.5485 32.2515 28.7474C30.623 30.9463 28.2549 32.4851 25.5816 33.0816C22.9084 33.678 20.1093 33.292 17.6981 31.9944L11.5001 33.6484ZM17.997 29.6984L18.3803 29.9254C20.1268 30.9588 22.1666 31.3865 24.182 31.1417C26.1974 30.8969 28.075 29.9935 29.5223 28.5721C30.9697 27.1507 31.9054 25.2913 32.1837 23.2835C32.462 21.2758 32.0672 19.2326 31.0608 17.4722C30.0545 15.7119 28.4931 14.3333 26.6201 13.5514C24.747 12.7694 22.6675 12.628 20.7055 13.1492C18.7436 13.6703 17.0094 14.8248 15.7733 16.4327C14.5371 18.0405 13.8685 20.0114 13.8715 22.0383C13.8698 23.7189 14.3354 25.367 15.2163 26.7991L15.4567 27.1947L14.5341 30.6194L17.997 29.6984Z" fill="#006CC0"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M27.3002 23.6139C27.0755 23.433 26.8124 23.3056 26.5309 23.2416C26.2495 23.1775 25.9571 23.1784 25.676 23.2442C25.2537 23.4193 24.9808 24.0809 24.708 24.4117C24.6504 24.491 24.5659 24.5466 24.4702 24.5681C24.3745 24.5896 24.2742 24.5755 24.1882 24.5285C22.6422 23.9239 21.3463 22.8153 20.511 21.3827C20.4398 21.2933 20.406 21.1798 20.4169 21.0661C20.4279 20.9524 20.4825 20.8473 20.5695 20.773C20.8738 20.4722 21.0973 20.0997 21.2192 19.6898C21.2462 19.2378 21.1425 18.7875 20.9203 18.3926C20.7485 17.8391 20.4217 17.3462 19.9783 16.9722C19.7496 16.8695 19.496 16.8351 19.2482 16.873C19.0003 16.911 18.7688 17.0198 18.5814 17.1862C18.2563 17.4663 17.9981 17.8157 17.8261 18.2086C17.6541 18.6014 17.5725 19.0278 17.5874 19.4563C17.5884 19.697 17.619 19.9366 17.6784 20.1698C17.8292 20.7302 18.0612 21.2655 18.367 21.7589C18.5877 22.1369 18.8284 22.5028 19.0882 22.8551C19.9325 24.0122 20.9938 24.9946 22.2132 25.7478C22.8251 26.1306 23.4791 26.4418 24.1622 26.6754C24.8719 26.9965 25.6554 27.1198 26.4296 27.0321C26.8707 26.9654 27.2887 26.7916 27.6467 26.5258C28.0047 26.26 28.2917 25.9104 28.4826 25.5079C28.5948 25.2647 28.6288 24.9928 28.5801 24.7295C28.4631 24.1912 27.742 23.8734 27.3002 23.6139Z" fill="#006CC0"/>
                  </svg>
                }
              />
              <SocialMedie
                linkPath="https://msng.link/o?420732355845=vi"
                theme='white'
                type= "mini"
                icon = {
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="46"
                    height="46"
                    fill="none"
                    viewBox="0 0 46 46"
                  >
                    <path
                      fill="#006CC0"
                      d="M17.632 32.403v-1.156a.117.117 0 00-.104-.136 6.682 6.682 0 01-2.605-1.415 6.262 6.262 0 01-1.343-1.724 9.363 9.363 0 01-.951-2.987 21.874 21.874 0 01-.189-3.943 19.42 19.42 0 01.115-1.836c.112-1.167.41-2.31.88-3.385a5.864 5.864 0 012.962-2.982c.903-.409 1.853-.708 2.828-.89.957-.179 1.926-.29 2.899-.33.877-.03 1.755-.017 2.63.04 1.254.052 2.496.257 3.698.612.892.254 1.74.64 2.517 1.143a5.373 5.373 0 011.778 2.025c.482.95.81 1.97.97 3.022.105.624.174 1.254.207 1.886.045.784.04 1.57.01 2.35s-.097 1.538-.194 2.302a8.476 8.476 0 01-1.046 3.264 6.061 6.061 0 01-3.328 2.704c-.96.334-1.953.571-2.962.709-.737.101-1.477.174-2.22.204-.569.026-1.138.02-1.706.01-.323 0-.643-.03-.964-.059a.164.164 0 00-.165.07 78.866 78.866 0 01-1.999 2.268 1.888 1.888 0 01-.424.35.796.796 0 01-1.194-.44c-.067-.21-.1-.43-.099-.65l-.001-1.026zm.783 1.464l.083-.076 1.47-1.618c.566-.622 1.131-1.245 1.696-1.869a.268.268 0 01.247-.108c.192.02.383.026.577.028.67 0 1.338 0 2.008-.047.607-.047 1.213-.12 1.815-.215.649-.111 1.29-.258 1.923-.437 1.409-.375 2.499-1.178 3.184-2.478.404-.8.658-1.665.748-2.554.107-.839.171-1.682.192-2.527a16.208 16.208 0 00-.218-3.17 8.912 8.912 0 00-.655-2.202c-.398-.872-.962-1.595-1.83-2.059-1.331-.712-2.771-1.029-4.256-1.191-.42-.046-.842-.07-1.264-.086a23.036 23.036 0 00-2.598.014c-.622.046-1.24.129-1.852.248-.9.162-1.77.457-2.583.872-.472.236-.9.55-1.265.927a5.81 5.81 0 00-1.271 2.284c-.29.986-.448 2.005-.47 3.032a22.779 22.779 0 00.038 3.081c.052.61.155 1.215.309 1.808a6.076 6.076 0 001.296 2.505 5.334 5.334 0 002.509 1.543c.121.036.176.078.176.214-.01.503 0 1.005 0 1.508l-.009 2.573z"
                    ></path>
                    <path
                      fill="#006CC0"
                      d="M17.183 18.283c-.012-.382.197-.66.45-.909a6.35 6.35 0 011.1-.835.887.887 0 011.139.184c.479.5.908 1.043 1.28 1.625.184.261.324.552.412.858a.69.69 0 01-.176.688c-.203.197-.42.38-.648.55a.744.744 0 00-.255.78c.14.659.46 1.268.924 1.761a5.35 5.35 0 002.113 1.548c.203.103.434.134.657.089a.603.603 0 00.31-.2c.123-.156.257-.306.368-.472a.98.98 0 011.331-.26c.597.343 1.16.74 1.684 1.187.123.104.252.206.375.312a.83.83 0 01.27.97 2.563 2.563 0 01-.47.775c-.249.344-.567.634-.934.851a1.311 1.311 0 01-1.17.075 15.483 15.483 0 01-3.95-2.301 15.383 15.383 0 01-3.028-3.315 13.726 13.726 0 01-1.673-3.334 4.211 4.211 0 01-.106-.435.919.919 0 01-.003-.192zM23.262 16.427a5.118 5.118 0 013.24 1.23 5.033 5.033 0 011.691 3.003c.077.424.118.853.123 1.284 0 .1-.026.174-.135.185-.11.01-.138-.082-.138-.175a5.796 5.796 0 00-.528-2.528 4.68 4.68 0 00-1.327-1.674 4.736 4.736 0 00-1.94-.917 5.238 5.238 0 00-1.142-.133.396.396 0 01-.111-.014.123.123 0 01-.086-.146.109.109 0 01.07-.101.111.111 0 01.044-.007c.08-.01.16-.007.24-.007z"
                    ></path>
                    <path
                      fill="#006CC0"
                      d="M26.662 21.159a3.08 3.08 0 00-.352-1.392 3.112 3.112 0 00-.946-1.086 3.346 3.346 0 00-1.564-.58c-.095-.013-.19-.018-.287-.029-.097-.01-.155-.05-.146-.148.009-.098.07-.133.176-.126 1.56.094 2.888.969 3.297 2.596.074.314.112.636.11.958v.087c0 .088-.056.145-.142.144a.14.14 0 01-.136-.152c-.014-.082-.01-.166-.01-.272z"
                    ></path>
                    <path
                      fill="#006CC0"
                      d="M23.928 19.215c.856.057 1.453.473 1.617 1.375.02.115.034.232.042.349 0 .094-.021.174-.125.174s-.139-.07-.143-.174c0-.346-.108-.683-.308-.967a1.306 1.306 0 00-.841-.468 1.912 1.912 0 00-.224-.027c-.123 0-.192-.061-.176-.156.016-.094.09-.111.158-.106z"
                    ></path>
                  </svg>
                }
              />
            </li>
          </ul>


        </div>

        <p className='footer__description' ref={elementRefs.description}>
          {contactInfo.subDescription}
        </p>

        <div className="footer__copyright" ref={elementRefs.copyright}>
          <p className="footer__copyright-text" >
            © {currentYear} {contactInfo.footerBottom}
          </p>
          
          <a href='https://www.webtime.cz/cz' target="_blank" className="footer__copyright-producer">
            <span>{contactInfo.producer}</span>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="36" height="36" rx="18" fill="white"/>
              <path fillRule="evenodd" clipRule="evenodd" d="M19.9249 27.7119C19.8816 27.7122 19.8381 27.7124 19.7944 27.7124H10.3008V12.1559H14.5231V16.3185H16.3344V19.5739H14.5231V24.3467H16.5942V22.0562H16.5969V15.4352H16.5942V12.1559H16.5956V11.88H10.3008V8.40075V8.2876H26.2V11.8792H20.8413V12.1555H26.2V13.3206C26.2004 13.35 26.2006 13.3795 26.2006 13.4091C26.2006 14.4207 25.9837 15.3162 25.5116 16.0702C25.0844 16.736 24.514 17.2662 23.8004 17.6609C23.9731 17.7216 24.1414 17.7891 24.3052 17.8635C25.1969 18.2497 25.8875 18.829 26.3771 19.6015C26.8841 20.374 27.1376 21.3212 27.1376 22.4431C27.1376 24.0984 26.5169 25.395 25.2756 26.333C24.2208 27.1144 22.7432 27.5638 20.8428 27.6812V27.7119H19.9249ZM20.8428 24.2269C21.2962 24.1354 21.6723 23.9914 21.9712 23.7949C22.5481 23.4087 22.8366 22.8017 22.8366 21.9741C22.8366 21.1648 22.5481 20.5671 21.9712 20.1809C21.7323 20.0081 21.4441 19.8723 21.1065 19.7733V15.7662C21.6655 15.38 21.9449 14.8008 21.9449 14.0287C21.9449 13.2746 21.6652 12.7137 21.1057 12.3458C21.0237 12.2874 20.936 12.2333 20.8428 12.1837V15.9211L20.844 15.9205V19.7059L20.8428 19.7057V24.2269Z" fill="url(#paint0_linear_920_274)"/>
              <defs>
              <linearGradient id="paint0_linear_920_274" x1="11.0619" y1="11.0949" x2="29.0519" y2="13.5217" gradientUnits="userSpaceOnUse">
              <stop stopColor="#2BBFFE"/>
              <stop offset="0.521875" stopColor="#8629FD"/>
              <stop offset="1" stopColor="#FC4AF5"/>
              </linearGradient>
              </defs>
            </svg>
          </a>
        </div>

      </footer>
    </div>

  )
}

export default Footer