import Footer from "./components/Footer/Footer.jsx";
import Contact from "./components/Contact/Contact.jsx";
import FAQ from "./components/FAQ/FAQ.jsx";
import Statistic from "./components/Statistic/Statistic.jsx";
import WorkProcess from "./components/WorkProcess/WorkProcess.jsx";
import Service from "./components/Service/Service.jsx";
import AboutUs from "./components/AboutUs/AboutUs";
import Home from "./components/Home/Home.jsx";
import { useEffect, useState } from "react";

function App() {

  document.body.classList.add("body-hidden"); 
  const [selectedLanguage, setSelectedLanguage] = useState('cz');
  

  const languageTexts = {
    ua: {
      home: {
        title1: "Компанія OKFG s.r.o.",
        title2: "Бізнес-зв'язок",
        title3: "між ЄС та Україною",
        nav1: "Про нас",
        nav2: "Послуги",
        nav3: "Як працюємо",
        nav4: "FAQ",
        nav5: "Контакти",
        description: "Аналіз ринку та пошук бізнес-можливостей між\nЄС та Україною.",

      },
      about: {
        title: "Про нас",
        desription1: "Ми чеська компанія, що спеціалізується на бізнес-зв'язках між країнами ЄС та Україною в області аналізу ринку, пошуку підходящих бізнес-партнерів, налаштування процедур імпорт/експорт та здійснення міжнародної торгівлі. На даний момент ми ведемо кілька значних міжнародних проєктів імпорт/експорт спільно з нашими надійними партнерами ЄС та Україною.",
        desription2: "Ми є ексклюзивний представник компанії AGRA GROUP a.s., ČESKÝ MÁK s.r.o. (провідні компаніі з переробки та продажу маку на ринках ЄС, Чехіі, Украіни). Ми працюємо на територіі ЄС, Скандинавії, Украіни, Молдови Та інші.",
        subTitle: "Ми працюємо на ринку з 2017 року."
      },
      statistic: {
        title: "Досягнення компанії OKFG s.r.o. у цифрах",
        number1: "1 719 795 €",
        text1: "Наш грошовий обіг компанії у 2022 р.",
        number2: "7 516 887 €",
        text2: "Грошовий обіг від посередницьких угод у 2022 р.",
        number3: "3344,75 тонн",
        text3: "товарів проданих у 2022 р.",
        number4: "153 шт вантажівок",
        text4: "завантажено та продано для наших партнерів  у 2022 р.",
      },
      service: {
        title: "Послуги",
        description1: "Продаж насіння маку голубого з Чехії.",
        description2: "Продаж цукру з України.",
        description3: "Продаж слимаків Helix з України.",
        description4: "Аналіз ринку та пошук бізнес-партнерів між ЄС та Україною.",
        description5: "Логістичні послуги Європа- Україна.",
        description6: "Виробництво та продаж піддонів та пакувальних матеріалів.",
      },
      work: {
        title: "Як ми працюємо,",
        subtitle: "3 кроки до початку співпраці",
        subTitle1: "Зв'яжіться з нами",
        description1: "Залиште нам повідомлення або зателефонуйте і дайте нам знати, як ми можемо допомогти Вам.",
        subTitle2: "Комерційна пропозиція",
        description2: "На основі Вашого запиту ми підготуємо для Вас детальний аналіз та комерційну пропозицію для нашої можливої співпраці.",
        subTitle3: "Співпраця з нами",
        description3: "Якщо ви приймаєте нашу пропозицію, ми підписуємо з Вами договір, і запускаємо процес співпраці згідно з Вашого запиту.",
      },

      faq: {
        title: "Питання і відповіді",
        question1: "Як відбувається аналіз ринку та пошук бізнес-партнерів в ЄС, Україні?",
        answer1SubTitle1: "Ми володіємо великою базою інформації, проводимо детальний аналіз ринку, допоможемо Вам укласти успішні контракти як з партнерами в Україні, так і ЄС з максимальною вигодою для обох сторін. ",
        answer1SubDescription1: " Детальний аналіз Вашого запиту: Ви хочете експортувати чи імпортувати? Який у вас товар ? Митний код товару? Ваші конкуренти? та інше.. Ми досліджуємо, проводимо швидкий аналіз потенційно перспективних ринків збуту, закупівлі продукції.",
        answer1SubDescription2: "Визначаємо рівень відповідності продукції законодавчим вимогам обраних цільових ринків.",
        answer1SubDescription3: "У разі можливості нашої подальшої співпраці надаємо Вам комерційну пропозицію.",
        answer1SubTitle2: "Додатково ми також можемо вам допомогти з:",
        answer1SubDescription4: "Переговорами щодо відповідних умов угоди (встановлення термінів оплати, умов доставки, якості, огляд продукту, претензії та арбітраж).",
        answer1SubDescription5: "Початковим імпортом/експортом, а після ми створимо керівництво для успішного імпорту/експорту.",
        answer1SubDescription6: "Регулярним ринковим аналізом (обсяги, ціни, конкуренти, аналіз), щоб тримати вас попереду конкурентів.",

        question2: "Чи потрібна Вам додаткова інформація що до купівлі насіння маку голубого?",
        answer2SubTitle1: "Яка поточна ринкова ціна на мак?",
        answer2SubDescription1: "Ціни на мак постійно змінюються та  залежать від багатьох факторів (кількість, якість, врожайність, рік врожаю та інш…). <br/> Для отримання інформаціі з приводу цін звертайтеся до нас.",
        answer2SubTitle2: "Цікавить питання стосовно якості, гарантіі якості на насіння маку?",
        answer2SubDescription2: "Ми можемо запропонувати Вам мак різних сортів, різноі якості під Ваш запит  (1-й ,  2-й і 3-й сорт). <br/> Залежно від вимог клієнта компанія AGRA GROUP a.s. впроваджує та використовує в роботі систему сертифікації  безпеки харчових продуктів (FSSC) 22000, Kosher та Halal.",
        answer2SubTitle3: "Чи можете Ви організувати транспортування маку?",
        answer2SubDescription3: "Так, ми маємо великий провідний логістичний відділ на території ЄС та України. <br/> За Вашим запитом наші логісти прорахують найкращий маршрут і ціни для Вас (доставка маку будь-який видом транспорту).",
        answer2SubTitle4: "Які умови оплати?",
        answer2SubDescription4: "Перші 2 поставки - спосіб оплати згідно договору 100% передоплата. Інші наступні поставки обговорюються  індивідуально з кожним кліентом.",
        answer2SubTitle5: "Який щорічний объем продажу маку?",
        answer2SubDescription5: "Середній щорічний объем продажу маку становить становить 5 000 тонн. <br/> Обьеми продажу безпосередньо залежать від врожаю  в даному році.",

        question3: "Чи потрібна  Вам додаткова інформація що до продажу піддонів та пакувальних матеріалів?",
        answer3: "Маємо в наявності до продажу  піддони EPAL нові та б/у. Ми співпрацюємо з виробниками піддонів та пакувальних матеріалів з України. А також пропонуємо під замовлення та за бажанням клієнта  виробництво нестандартних піддонів (Наприклад :  118x100 см, 120x100 см, 134x100 см, 98x98 см). <br/> Зв'яжіться з нами для отримання подальшої консультації.",

        question4: "Чи потрібна Вам додаткова інформація що до купівлі цукру з України?",
        answer4: "Поточні ціни залежать від багатьох факторів: якості цукру, объему, умов поставки та інше. <br/> Ви можете надіслати нам запит, специфікацію що до якості цукру та місце доставки. <br/> Ми поставляємо цукор першого та другого сорту.",
        question5: "Чи потрібна Вам додаткова інформація про логістичні послуги Європа/Україна?",
        answer5: "Залиште Ваш запит на перевезення вашого вантажу і  ми прорахуємо найкращий маршрут і ціни  для Вас."
      },
      contact: {
        title: "Зв'яжіться з нами - Контакти",
        subTitle1: "Ім'я та Прізвище",
        input1: "Ваше ім'я та прізвище",
        subTitle2: "Електронна пошта",
        input2: "Ваша електронна пошта",
        subTitle3: "Телефон",
        input3: "Ваш телефон",
        subTitle4: "Повідомлення для нас",
        input4: "Залиште Ваш запит",
        button: "Надіслати запит.",
        popupButton: "Успішно відправлено",  

        error1: "небув знайдений",
        error2: "Недійсний e-mail",

        cart1: {
          name: "Андрій Качірек",
          position: "Генеральний директор",
        },
        cart2: {
          name: "Євген Белоусов",
          position: "Менеджер з експорту",
        },
        cart3: {
          name: "Наталія Смирнова",
          position: "Логістичний менеджер",
        },
        cart4: {
          sales: "Продажі",
          invoicing: "Рахунки",
        },

        description: "Аналіз та пошук можливостей для бізнесу між ЄС та Україною.",
        titleNav: "Меню",
        titleNav2: "Реквізити компанії",
        titleNav3: "Контакти",
        nav1: "Про нас",
        nav2: "Послуги",
        nav3: "Як ми працюємо",
        nav4: "FAQ",
        nav5: "Контакти",
        subDescription: "OKFG s. r. o., Holubova 746, 530 03 Пардубіце, Чеська Республіка зареєстрована в Торговельному реєстрі за Муніципальним судом в Градець-Кралове, розділ C 40341.",
        footerBottom: "Всі права захищені.",
        workSchedule: "Пн-Пт",
        cin: "ЄДРПО",
        address: "Голубова 746, 530 03 Пардубіце,\nЧехія",
        producer: "Дизайн та виготовлення сайту -",
      }
      
    },
    eu: {
      home: {
        title1: "Company OKFG s.r.o.",
        title2: "Business connection",
        title3: "between the EU and Ukraine",
        nav1: "About us",
        nav2: "Services",
        nav3: "How we work",
        nav4: "FAQ",
        nav5: "Contacts",
        description: "Market analysis and search for business opportunities between the EU and Ukraine.",
      },
      about: {
        title: "About us",
        desription1: "We are a Czech company focusing on business connections between EU countries and Ukraine in the areas of market analysis, finding suitable business partners, setting up import/export procedures, and conducting trade. We also manage several import/export projects in cooperation with significant EU partners.",
        desription2: "We are the exclusive representative of AGRA GROUP a.s. and its subsidiary ČESKÝ MÁK s.r.o., leading companies in the processing and sale of blue poppy both in the Czech and European markets. Our business representation covers Ukraine, Moldova, EU and Scandinavian countries.",
        subTitle: "We have been operating in the market since 2017."
      },
      statistic: {
        title: "Our Work in Numbers",
        number1: "1 719 795 €",
        text1: "company turnover in 2022",
        number2: "7 516 887 €",
        text2: "turnover from mediated transactions by OKFG s.r.o. in 2022",
        number3: "3344,75 tons",
        text3: "of the goods sold and mediated by OKFG s.r.o. in 2022",
        number4: "153 trucks",
        text4: "of sold and mediated goods by OKFG s.r.o. in 2022",
      },
      service: {
        title: "Services",
        description1: "Sale of Czech Blue Poppy Seeds.",
        description2: "Sale of sugar from Ukraine.",
        description3: "Sale of Helix snails from Ukraine.",
        description4: "Market analysis and search of business partners between the EU and Ukraine.",
        description5: "Logistics services EU/Ukraine. ",
        description6: "Production and sale of pallets and packaging materials.",
      },
      work: {
        title: "How We Work,",
        subtitle: "3 Steps to Start Cooperation",
        subTitle1: "Contact us and leave us your inquiry",
        description1: "Leave us a message or call us directly and let us know how we can help you.",
        subTitle2: "Commercial offer",
        description2: "Based on your request, we will analyse the situation and present a offer for potential cooperation.",
        subTitle3: "Cooperation upon acceptance of our offer",
        description3: "If you accept our offer, we will initiate further steps in cooperation according to the specific trade.",
      },

      faq: {
        title: "FAQ",
        question1: "How does the market analysis and search for business partners between the EU and Ukraine proceed?",
        answer1SubTitle1: "Based on current data, detailed information, and market knowledge, we will help you with finding new business partnerships between the EU and Ukraine.",
        answer1SubDescription1: "Initially, we will clarify more information about your requirements. Do you want to export or import? What is your product/goods, and what is its customs code? What is your competition?",
    
        answer1SubDescription2: "We will make a quick market analysis, and if we can help you, we will present a exact cooperation offer.",
    
        answer1SubDescription3: "Upon acceptance of our offer, we will enure a comprehensive market analysis and search for business partners. Depending on complexity, the analysis usually takes about 2-3 weeks. After, we will personally present the analyses to you and provide a list of suitable potential business partners.",
    
        answer1SubTitle2: "Additionally, we can also assist you with:",
        answer1SubDescription4: "Negotiating suitable Contract terms (setting payment terms, delivery conditions, product quality and inspection, claims, and arbitration).",
    
        answer1SubDescription5: "Initial import/export and after we will create a guideline for successful import/export.",
    
        answer1SubDescription6: "Regular market analysis (volumes, prices, competitive analyses) to keep you ahead.",
    

        question2: "Need more information about Czech Blue Poppy Seeds?",
        answer2SubTitle1: "What is the current price of Czech Blue Poppy Seeds?",
        answer2SubDescription1: "Prices of Czech Blue Poppy Seeeds are volatile and also depend on several factors (quantity, quality and year of crop), so feel free to contact us for current prices.",
    
        answer2SubTitle2: "What quality of Czech Blue Poppy Seeds do you offer, and how do you guarantee quality?",
        answer2SubDescription2: "We can offer the highest quality Czech Blue Poppy Seeds and also Poppy of 2nd and 3rd quality, depending on customer requirements. <br/> AGRA GROUP a.s. has implemented and uses the Food Safety System Certification (FSSC) 22000, Kosher, and Halal systems. If you need product specifications, don't hesitate to contact us.",
    
        answer2SubTitle3: "Can you arrange transportation to the customer?",
        answer2SubDescription3: "Yes, we work with several leading EU and Ukraine logistics partners. <br/> Throughout the EU, we can offer delivery of Czech Blue Poppy Seeds according to INCOTERMS DAP conditions.",
    
        answer2SubTitle4: "What are the payment terms?",
        answer2SubDescription4: "For the first 2 transactions a 100% advance payment is required. After, we can offer payment after a delivery (with payment insurance from a financial institution).",
    
        answer2SubTitle5: "What is your annual sales volume of Czech Blue Poppy Seeds?",
        answer2SubDescription5: "Our annual volume is 5,000 tons of Czech Blue Poppy Seeds. It depends on the volume of harvested poppy in a given year.",
    

        question3: "Need more information about the sale of pallets and packaging materials?",
        answer3: "We offer EPAL pallets, both new and used. <br/> We cooperate with pallet and packaging material manufacturers in western Ukraine. <br/> We also offer individual production of atypical pallets according to customer requirements (e.g., 118x100 cm, 120x100 cm, 134x100 cm, 98x98 cm). <br/> Contact us for a current prices.",
    

        question4: "Need more information about sugar from Ukraine?",
        answer4: "Current prices depend on the quality of sugar and delivery conditions. <br/> Please send us your quality specification for sugar and requested delivery place, then we will send you an actual offer for sugar. <br/> We deliver first and second quality of sugar.",
        
        
        question5: "Need more information about logistics services to/from Ukraine?",
        answer5: "Leave us a message regarding your shipping requirements. We will calculate and submit a transport offer with the best price.",
      },
    
      contact: {
        title: "Contact Us",
        subTitle1: "First and Last Name",
        input1: "Your first and last name",
        subTitle2: "E-mail",
        input2: "Your e-mail",
        subTitle3: "Phone",
        input3: "Your phone number",
        subTitle4: "Message for us",
        input4: "Leave us a message about your interests. Please specify your requirements.",
        button: "Send",
        popupButton: "Successfully Sent",
        error1: "was not found",
        error2: "Invalid email",

        cart1: {
          name: "Ondřej Kačírek",
          position: "Managing Director",
        },
        cart2: {
          name: "Yevhen Belousov",
          position: "Export Manager",
        },
        cart3: {
          name: "Natalia Smirnova",
          position: "Logistic manager",
        },
        cart4: {
          sales: "Sales",
          invoicing: "Invoices",
        },
        description: "Market analysis and search for business opportunities between the EU and Ukraine.",
        titleNav: "Navigation",
        titleNav2: "Company details",
        titleNav3: "Contacts",
        nav1: "About us",
        nav2: "Services",
        nav3: "How we work",
        nav4: "FAQ",
        nav5: "Contacts",
        subDescription: "OKFG s. r. o., Holubova 746, 530 03 Pardubice, is a Czech company registered at the Regional Commercial Court in Hradec Králové, section C, file 40341.",
        footerBottom: "All rights reserved.",
        workSchedule: "Mon-Fri",
        cin: "CIN",
        address: "Holubova 746, 530 03 Pardubice,\nCzech Republic",
        producer: "Design and production -",
      }
      
    },
    cz: {
      home: {
        title1: "Společnost OKFG s.r.o.",
        title2: "Obchodní propojení",
        title3: "mezi EU a Ukrajinou",
        nav1: "O nás",
        nav2: "Služby",
        nav3: "Jak pracujeme",
        nav4: "FAQ",
        nav5: "Kontakty",
        description: "Analýza a vyhledávání obchodních příležitostí mezi EU a Ukrajinou.",
      },
      about: {
        title: "O nás",
        desription1: "Jsme česká společnost specializující se na obchodní propojování mezi zeměmi EU a Ukrajinou v oblasti analýz trhů, hledání vhodných obchodních partnerů, nastavení import/export postupů a realizaci obchodů. Současně spravujeme několik import/export projektů v součinnosti s významnými EU partnery.",
        desription2: "Jsme exkluzivním zástupcem společností AGRA GROUP a.s. a její dceřiné společnosti ČESKÝ MÁK s.r.o., které jsou předními lídry v oblasti zpracování a prodeje modrého máku jak na českém, tak i evropském trhu.",
        subTitle: "Na trhu působíme od roku 2017."
      },
      statistic: {
        title: "Naše práce v číslech",
        number1: "1 719 795 €",
        text1: "obrat společnosti v roce 2022",
        number2: "7 516 887 €",
        text2: "tržby zprostředkovaných obchodů OKFG s.r.o. v roce 2022",
        number3: "3344,75 tun",
        text3: "prodaného a zprostředkovaného zboží OKFG s.r.o. v roce 2022",
        number4: "153 kamionů",
        text4: "prodaného a zprostředkovaného zboží OKFG s.r.o. v roce 2022",
      },
      service: {
        title: "Služby",
        description1: "Prodej českého modrého máku.",
        description2: "Prodej cukru z Ukrajiny.",
        description3: "Prodej hlemýžďů Helix z Ukrajiny.",
        description4: "Analýza trhu a vyhledávání obchodních partnerů mezi EU a Ukrajinou.",
        description5: "Logistické služby do/z Ukrajiny.",
        description6: "Výroba a prodej palet a obalových materiálů.",
      },
      work: {
        title: "Jak pracujeme,",
        subtitle: "3 kroky pro zahájení spolupráce",
        subTitle1: "Kontaktujte nás a zanechte svoji poptávku",
        description1: "Zanechte nám zprávu, nebo nám rovnou zavolejte a sdělte nám s čím potřebujete pomoci.",
        subTitle2: "Cenová nabídka",
        description2: "Na základě Vašeho požadavku zanalyzujeme situaci a předložíme nabídku případné spolupráce.",
        subTitle3: "Spolupráce v případě akceptace naší nabídky",
        description3: "V případě akceptace naší nabídky zahájíme další kroky spolupráce dle konkrétního obchodu.",
      },
      faq: {
        title: "FAQ",
        question1: "Jak probíhá analýza trhu a vyhledávání obchodních partnerů EU/Ukrajina?",
        answer1SubTitle1: "Na základě aktuálních dat, podrobných údajů a znalosti trhů Vám pomůžeme najít nové obchodní partnerství mezi EU a Ukrajinou.",
        answer1SubDescription1: "Prvotně si upřesníme bližší informace o Vašich požadavcích. <br/> Chcete vyvážet, nebo dovážet? Jaký je Váš výrobek/zboží a jaký je jeho celní kód? Jaká je Vaše konkurence?",
        answer1SubDescription2: "Provedeme rychlou analýzu trhu a v případě, že Vám dokážeme pomoci, předložíme konkrétní nabídku spolupráce.",
        answer1SubDescription3: " V případě akceptace naší nabídky provedeme kompletní analýzu trhu a vyhledávání obchodních partnerů. Záleží na náročnosti, ale obvykle nám analýza zabere zhruba 2-3 týdny, poté Vám osobně odprezentujeme analýzy a předložíme seznam vhodných potenciálních partnerů.",
        answer1SubTitle2: "Dodatečně Vám můžeme také pomoci s:",
        answer1SubDescription4: "Vyjednáváním vhodných smluvních podmínek (nastavení platebních podmínek, dodacích podmínek, kvalita a kontrola zboží, reklamace a arbitráž).",
        answer1SubDescription5: "Prvotním importem/exportem a následným vytvořením směrnice pro následný úspěšný import/export.",
        answer1SubDescription6: "Pravidelnou analýzou trhu (objemy, ceny, analýzy konkurence) tak, abyste byly vždy o krok napřed.",

        question2: "Potřebujete vědět více informací o českém modrém máku?",
        answer2SubTitle1: "Jaká je aktuální cena českého modrého máku?",
        answer2SubDescription1: "Ceny českého máku jsou volatilní a také záleží na několika faktorech (množství, kvalita a rok sklizně), pro aktuální ceny nás proto neváhejte kontaktovat.",
        answer2SubTitle2: "Jakou kvalitu máku nabízíte a garantujete kvalitu?",
        answer2SubDescription2: "Můžeme nabídnout jak mák nejvyšší, tak i 2. a 3. kvality. <br/> Vše záleží na požadavku zákazníka. <br/> AGRA GROUP a.s. zavedla a používá systém Food Safety System Certification (FSSC) 22000, Kosher a Halal. <br/> Pokud potřebujete produktovou specifikaci, neváhejte nás kontaktovat.",
        answer2SubTitle3: "Dokážete zajistit přepravu k zákazníkovi?",
        answer2SubDescription3: "Ano, pracujeme jak s několika předními EU, tak i UA logistickými partnery. <br/> Po celé EU můžeme nabídnout dodání máku dle INCOTERMS podmínky DAP.",
        answer2SubTitle4: "Jaké jsou platební podmínky?",
        answer2SubDescription4: "Pro první 2 obchody je vyžadována 100% platba předem. Poté můžeme nabídnout platbu po dodání (v případě připojištění platby některou z finančních institucí).",
        answer2SubTitle5: "Jaký je Váš roční objem prodeje českého modrého máku?",
        answer2SubDescription5: "Náš roční objem činí 5 000 tun modrého máku. Vždy záleží na objemu sklizeného máku v daném roce.",
        question3: "Potřebujete vědět více informací o prodeji palet a obalových materiálů?",
        answer3: "Nabízíme jak nové, tak i použité EPAL palety. Spolupracujeme s výrobci palet a obalových materiálů na západní Ukrajině. <br/> Nabízíme také individuální výrobu atypických palet dle požadavků zákazníka. Např. 118x100 cm, 120x100 cm, 134x100 cm, 98x98 cm. <br/> Pro konkrétní ceny nás neváhejte kontaktovat.",
        question4: "Potřebujete vědět více informací o cukru z Ukrajiny?",
        answer4: "Konkrétní ceny závisí na kvalitě cukru a podmínkách dodání dle INCOTERMS. <br/> Prosím, zašlete nám svoji specifikaci na kvalitu cukru a požadované místo dodání, následně Vám zašleme aktuální cenovou nabídku cukru. <br/> Dodáváme cukr jak první, tak druhé kvality.",
        question5: "Potřebujete vědět více informací o zajišťování logistických služeb do/z Ukrajiny?",
        answer5: "Zanechte nám zprávu ohledně požadavků Vaší přepravy. Následně propočítáme a předložíme nabídku přepravy s nejlepší cenou."
      },
      contact: {
        title: "Kontaktujte nás",
        subTitle1: "Jméno a příjmení",
        input1: "Vaše jméno a příjmení",
        subTitle2: "E-mail",
        input2: "Váš e-mail",
        subTitle3: "Telefon",
        input3: "Váš telefon",
        subTitle4: "Zpráva pro nás",
        input4: "Zanechte nám zprávu, co Vás zajímá. Specifikujte, prosím, své požadavky.",
        button: "Odeslat můj dotaz.",
        popupButton: "Odesláno úspěšně",

        
        error1: "nebyl nalezen",
        error2: "Neplatný e-mail",

        cart1: {
          name: "Ondřej Kačírek",
          position: "Jednatel společnosti",
        },
        cart2: {
          name: "Yevhen Belousov",
          position: "Export manažer",
        },
        cart3: {
          name: "Natalia Smirnova",
          position: "Logistický manažer",
        },
        cart4: {
          sales: "Sales",
          invoicing: "Fakturace",
        },
        description: "Analýza a vyhledávání obchodních příležitostí mezi EU a Ukrajinou.",
        titleNav: "Navigace",
        titleNav2: "Kontakty",

        titleNav: "Navigace",
        titleNav2: "Firemní detaily",
        titleNav3: "Kontakty",
        nav1: "O nás",
        nav2: "Služby",
        nav3: "Jak pracujeme",
        nav4: "FAQ",
        nav5: "Kontakty",
        subDescription: "OKFG s. r. o., Holubova 746, 530 03 Pardubice, je česká společnost registrovaná u Krajského obchodního soudu v Hradci Králové, oddělení C, složka 40341.",
        footerBottom: "Všechna práva vyhrazena.",
        workSchedule: "Po-Pa",
        cin: "IČO",
        address: "Holubova 746, 530 03 Pardubice,\nČeská Republika",
        producer: "Návrh a výroba webu -",
      }
    }
  };

  // function changePageTitle() {
  //   useEffect(() => {
  //     document.title = `${languageTexts[selectedLanguage].home.description}`;
  //   }, []);
  // }

  
  return (

    <div className="App">
      
      <Home setSelectedLanguage={setSelectedLanguage} selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <AboutUs selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <Statistic  selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <Service selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <WorkProcess selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <FAQ selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <Contact selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
      <Footer selectedLanguage={selectedLanguage} languageTexts={languageTexts}/>
    </div>
  );
}

export default App;
