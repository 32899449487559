import React, { useEffect, useRef, useState } from 'react';
import "./style/Contact.css";
import { useForm } from '@formspree/react';

const Contact = ({ languageTexts, selectedLanguage }) => {
  const [, handleSubmit] = useForm("xzbnbeny");
  const [showMessage, setShowMessage] = useState(false);
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const elementRefs = {
    title: useRef(null),
    left: useRef(null),
    right: useRef(null),
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  useEffect(() => {
    const handleScroll = () => {
      Object.values(elementRefs).forEach((ref) => {
        const element = ref.current;

        if (element) {
          const rect = element.getBoundingClientRect();
          const windowHeight = window.innerHeight || document.documentElement.clientHeight;

          if (rect.top <= windowHeight * 0.9) {
            element.classList.add('animate');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [elementRefs]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear error message when input changes
    setErrors(prevState => ({
      ...prevState,
      [name]: ''
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
  
    let hasError = false;
    const newErrors = {};
  
    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(form.email.trim())) {
      newErrors.email = `${languageTexts[selectedLanguage].contact.error2}`;
      hasError = true;
    }
  
    // Check if any input field is empty
    for (const key in form) {
      if (form.hasOwnProperty(key) && !form[key].trim()) {
        console.log(key)

        if (key == "name") {
          console.log(true)
          newErrors[key] =  `${languageTexts[selectedLanguage].contact.subTitle1} ${languageTexts[selectedLanguage].contact.error1}`;
        } 

        if (key == "email") {
          console.log(true)
          newErrors[key] =  `${languageTexts[selectedLanguage].contact.subTitle2}  ${languageTexts[selectedLanguage].contact.error1}`;
        } 

        if (key == "phone") {
          console.log(true)
          newErrors[key] =  `${languageTexts[selectedLanguage].contact.subTitle3}  ${languageTexts[selectedLanguage].contact.error1}`;
        } 

        if (key == "message") {
          console.log(true)
          newErrors[key] =  `${languageTexts[selectedLanguage].contact.subTitle4}  ${languageTexts[selectedLanguage].contact.error1}`;
        } 

        hasError = true;
      }
    }
  
    if (hasError) {
      setErrors(newErrors);
      return;
    }
  
    handleSubmit(e);
    setShowMessage(true);

    setForm({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
  };
  

  return (
    <div className="contact__wrapper" id='contact'>
      <div className='contact'>
        <div className={`contact__message ${showMessage ? "_active" : ""}`}>
          <p>
            {languageTexts[selectedLanguage].contact.popupButton}
          </p>
        </div>
        <p className='contact__title secondary-title' ref={elementRefs.title}>{languageTexts[selectedLanguage].contact.title}</p>
        <div className="contact__box">
          <form onSubmit={handleFormSubmit} className="contact__box-left" ref={elementRefs.left}>
            <div className="contact__secondary">
              <p className='contact__secondary-title'>{languageTexts[selectedLanguage].contact.subTitle1}</p>
              <input className='contact__secondary-input' type="text" placeholder={languageTexts[selectedLanguage].contact.input1} name='name' value={form.name} onChange={handleInputChange} />
              <p className="error-message">{errors.name}</p>
            </div>

            <div className="contact__secondary">
              <p className='contact__secondary-title'>{languageTexts[selectedLanguage].contact.subTitle2}</p>
              <input className='contact__secondary-input' type="email" placeholder={languageTexts[selectedLanguage].contact.input2} name="email" value={form.email} onChange={handleInputChange} />
              <p className="error-message">{errors.email}</p>
            </div>

            <div className="contact__secondary">
              <p className='contact__secondary-title'>{languageTexts[selectedLanguage].contact.subTitle3}</p>
              <input className='contact__secondary-input' type="tel" placeholder={languageTexts[selectedLanguage].contact.input3} name='phone' value={form.phone} onChange={handleInputChange} />
              <p className="error-message">{errors.phone}</p>
            </div>

            <div className="contact__secondary">
              <p className='contact__secondary-title'>{languageTexts[selectedLanguage].contact.subTitle4}</p>
              <textarea className='contact__secondary-input contact__secondary-input2 ' type="text" placeholder={languageTexts[selectedLanguage].contact.input4} name="message" value={form.message} onChange={handleInputChange} />
              <p className="error-message">{errors.message}</p>
            </div>

            <button type="submit" className='contact__button'>{languageTexts[selectedLanguage].contact.button}</button>
          </form>
          <div className="contact__box-rigth" ref={elementRefs.right}>
            <div className="main">
              <div className="main__element">
                <h1 className='main__element-title'>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.3743235341167!2d15.787386076740816!3d50.04181877151709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470dccf1710ec0bd%3A0x31fc387872900255!2zSG9sdWJvdmEgNzQ2LCA1MzAgMDMgUGFyZHViaWNlIEktQsOtbMOpIFDFmWVkbcSbc3TDrQ!5e0!3m2!1sen!2scz!4v1702668383348!5m2!1sen!2scz"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Google Maps Location"
                  ></iframe>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
